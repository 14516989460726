import { Pipe, PipeTransform } from '@angular/core';
import { AppRouterService } from '../services/app-router.service';
import { RequestParams } from '../models/sys/routing';

@Pipe({
  name: 'route',
  pure: false, // translation dependant
})
export class RoutePipe implements PipeTransform {
  public constructor(private readonly _appRouter: AppRouterService) {}

  /**
   * Format a route & map given parameters
   */
  public transform(value: string, args?: RequestParams): string {
    return this._appRouter.prepareRoute(value, args);
  }
}
