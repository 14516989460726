import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from 'src/app/shared/models/dto/catalog/option';

@Component({
  selector: 'app-cart-select',
  templateUrl: './cart-select.component.html',
  styleUrl: './cart-select.component.scss',
})
export class CartSelectComponent {
  @Input({ required: true }) public label?: string;
  @Input({ required: true }) public value?: Option;
  @Input() public options?: Option[];
  @Input() public condensed: boolean = false;
  @Input() public open: boolean = false;

  public disabled: boolean = false;

  @Output() public readonly optionChange: EventEmitter<Option>;

  public constructor() {
    this.optionChange = new EventEmitter<Option>();
  }

  public toggle(): void {
    if (this.options) {
      this.open = !this.open;
    }
  }

  public onClick(option: Option): void {
    this.optionChange.emit(option);
    this.open = false;
  }
}
