import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AppRouterService } from '../../services/app-router.service';
import { Routes } from '../../constants/routes/routes.constant';
import { CookieStorageService } from '../../services/storage/cookies-storage.service';
import { StorageKeys } from '../../enums/storage-keys.enum';
import { Locale } from '../../enums/i18n/locale.enum';

export const rootRedirectGuard: CanActivateFn = (): boolean => {
  // Previously, we were redirecting to the browser first locale available
  // Redirection is now handled by the express server so this guard shouldn't be called
  const appRouter: AppRouterService = inject(AppRouterService);
  const cookiesStorage: CookieStorageService = inject(CookieStorageService);

  // Only useful for ng serve (dev mode), otherwise the server handles the redirection
  const locale: string | null = cookiesStorage.get(StorageKeys.userLocale);
  if (Object.values(Locale).indexOf(locale as Locale) !== -1) {
    appRouter.navigate(Routes.home, undefined, { locale });
  } else {
    appRouter.navigate(Routes.home);
  }

  return false;
};
