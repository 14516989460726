import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { combineLatest } from 'rxjs';
import { Locale } from '../../enums/i18n/locale.enum';
import { Routes } from '../../constants/routes/routes.constant';
import { StructuredDataKeys } from '../../enums/structured-data-keys.enum';
import { Channel } from '../../models/dto/channel';
import { Schema } from '../../models/sys/schema-org';
import { organizationMapper } from '../../utils/mappers/schema-mappers.utils';
import { AppRouterService } from '../app-router.service';
import { AppStateService } from '../app-state.service';

@Injectable({
  providedIn: 'root',
})
export class StructuredDataService {
  private readonly _map: Map<StructuredDataKeys, HTMLScriptElement>;
  private readonly _renderer: Renderer2;

  public constructor(
    factory: RendererFactory2,
    private readonly _appState: AppStateService,
    private readonly _appRouter: AppRouterService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    this._map = new Map<StructuredDataKeys, HTMLScriptElement>();
    this._renderer = factory.createRenderer(this._document, null);
  }

  public bootstrap(): void {
    this.clean(); // We need to clean at bootstrap otherwise ssr & non ssr schema stacks
    combineLatest([this._appState.locale$, this._appState.channel$]).subscribe(
      ([locale, channel]: [Locale, Channel | undefined]) => {
        this.set(
          StructuredDataKeys.organization,
          organizationMapper(this._appRouter.prepareRoute(Routes.home), channel)
        );
      }
    );
  }

  public set(key: StructuredDataKeys, schema: Schema): void {
    this.delete(key);

    const script: HTMLScriptElement = this._document.createElement('script');
    script.type = 'application/ld+json';
    script.innerText = JSON.stringify({
      '@context': 'https://schema.org',
      ...schema,
    });

    this._map.set(key, script);
    this._renderer.appendChild(this._document.head, script);
  }

  public delete(key: StructuredDataKeys): void {
    const script: HTMLScriptElement | undefined = this._map.get(key);
    if (script) {
      this._renderer.removeChild(this._document.head, script);
      this._map.delete(key);
    }
  }

  private clean(): void {
    this._document
      .querySelectorAll('head script[type="application/ld+json"]')
      .forEach((value: Element) => {
        this._renderer.removeChild(value.parentElement, value);
      });
  }
}
