import { Component, Input } from '@angular/core';
import { Variant } from 'src/app/shared/models/dto/catalog/variant';
import { AbstractComponent } from '../../abstract.component';

@Component({
  selector: 'app-suggestion-card',
  templateUrl: './suggestion-card.component.html',
  styleUrls: ['./suggestion-card.component.scss'],
})
export class SuggestionCardComponent extends AbstractComponent {
  @Input({ required: true }) public variant?: Variant;
  @Input() public disabled: boolean = false;
  @Input() public grey: boolean = false;

  public constructor() {
    super();
  }
}
