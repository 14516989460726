import { Component, Input } from '@angular/core';
import { Price } from 'src/app/shared/models/dto/catalog/price';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss'],
})
export class PriceComponent {
  @Input({ required: true }) public price?: Price;
  @Input() public condensed: boolean = false;
  @Input() public reverse: boolean = false;
}
