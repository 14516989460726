import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../enums/i18n/currency.enum';
import { OrderItem } from '../models/dto/cart/order';
import { Price } from '../models/dto/catalog/price';

@Pipe({
  name: 'orderItemPrice',
})
export class OrderItemPricePipe implements PipeTransform {
  public constructor() {}

  public transform(item: OrderItem, currency?: Currency): Price {
    return {
      price: item.subtotal,
      original_price: (item.originalUnitPrice ?? 0) * (item.quantity ?? 1),
      currency,
    };
  }
}
