import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Option } from 'src/app/shared/models/dto/catalog/option';
import { Variant } from 'src/app/shared/models/dto/catalog/variant';
import {
  findClosestVariant,
  findDiscriminant,
  hasOption,
} from 'src/app/shared/utils/options.utils';

@Component({
  selector: 'app-cart-options',
  templateUrl: './cart-options.component.html',
  styleUrl: './cart-options.component.scss',
})
export class CartOptionsComponent implements OnChanges {
  @Input({ required: true }) public variant?: Variant;
  @Input() public condensed: boolean = false;

  @Input() public alts?: Variant[];
  @Input() public options?: {
    [key: string]: Option[];
  };

  @Output() public readonly variantChange: EventEmitter<Variant>;

  public activeOptions?: {
    [key: string]: Option[];
  };

  public constructor() {
    this.variantChange = new EventEmitter<Variant>();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.activeOptions = undefined;
    if (this.alts?.length) {
      this.activeOptions = {};

      const discriminant: Option | undefined = this.variant
        ? findDiscriminant(this.variant)
        : undefined;

      if (!discriminant) {
        this.activeOptions = this.options;
        return;
      }

      for (const key in this.options) {
        let options: Option[];
        if (key === discriminant.type) {
          options = this.options[key];
        } else {
          options = this.filterByDiscriminant(discriminant, this.options[key]);
        }

        // On affiche un select uniquement si au moins 2 options sont disponibles
        if ((options?.length ?? 0) > 1) {
          this.activeOptions[key] = options;
        }
      }
    }
  }

  public onOptionChange(option: Option): void {
    if (this.variant && this.alts?.length) {
      const variant: Variant | undefined = findClosestVariant(
        option,
        this.variant,
        this.alts
      );
      if (variant) {
        this.variantChange.emit(variant);
      }
    }
  }

  private filterByDiscriminant(
    discriminant: Option,
    options: Option[]
  ): Option[] {
    const filtered: Option[] = [];
    for (const o of options) {
      for (const v of this.alts ?? []) {
        if (
          hasOption(o.code ?? '', v) &&
          hasOption(discriminant.code ?? '', v)
        ) {
          filtered.push(o);
        }
      }
    }

    return filtered;
  }
}
