import { OptionTypes } from '../enums/option-types.enum';
import { Option } from '../models/dto/catalog/option';
import { Variant } from '../models/dto/catalog/variant';

/**
 * Find an alternative variant with a specific option and either all matching option or the most
 */
export const findClosestVariant = (
  option: Option,
  ref: Variant,
  alts: Variant[]
): Variant | undefined => {
  let variants: Variant[] = alts.filter((v: Variant) => {
    return v.code !== ref.code && hasOption(option.code ?? '', v);
  });

  const discriminant: Option | undefined = findDiscriminant(ref);
  variants = variants
    .filter(
      (v: Variant) =>
        !discriminant || // no discriminant
        discriminant.type === option.type || // discriminant is the same as the option
        hasOption(discriminant.code ?? '', v) // discriminant is present in the variant
    )
    .sort(
      (v1: Variant, v2: Variant) =>
        countMatchingOptions(ref, v2) - countMatchingOptions(ref, v1)
    );

  return variants.length ? variants[0] : undefined;
};

export const findDiscriminant = (v: Variant): Option | undefined => {
  let option: Option | undefined;
  [OptionTypes.wheel, OptionTypes.color].forEach((type: OptionTypes) => {
    if (!option) {
      option = v.options?.[type];
    }
  });

  return option;
};

export const hasOption = (code: string, variant: Variant): boolean => {
  return !!(
    variant.options &&
    Object.keys(variant.options)
      .map((key: string) => {
        return variant.options?.[key];
      })
      .find((o?: Option) => {
        return o?.code === code;
      })
  );
};

export const countMatchingOptions = (v1: Variant, v2: Variant): number => {
  let count: number = 0;
  for (const k1 in v1.options) {
    for (const k2 in v2.options) {
      if (k1 === k2 && v1.options[k1].code === v2.options[k2].code) {
        count++;
      }
    }
  }

  return count;
};
