import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Image } from 'src/app/shared/models/dto/image';
import { AbstractComponent } from '../../abstract.component';

interface ImageProps {
  src: string;
  alt?: string;
  title?: string;
  srcset?: string;
}

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent extends AbstractComponent implements OnChanges {
  @Input({ required: true }) public image?:
    | (Image | string)[]
    | (Image | string);
  @Input() public display?: 'fit' | 'cover';
  @Input() public alt?: string;
  @Input() public title?: string;
  @Input() public draggable: boolean = true;
  @Input() public usePath: boolean = false; // obviously only works if this.image is an Image

  @Input() public guessHeight: boolean = true;

  @Input() public maxWidth?: string;
  @Input() public maxHeight?: string;

  public loaded: boolean = false;

  public props?: ImageProps;

  public ngOnChanges(changes: SimpleChanges): void {
    let props: ImageProps;
    if (typeof this.image === 'string') {
      props = this.stringProps(this.image);
    } else if (Array.isArray(this.image)) {
      if (this.image?.length) {
        // If we have multiple images we get the biggest image as main image
        // If we have strings it wont work well so we prioritize the clean images
        const biggest: Image | string = this.image.reduce(
          (acc: Image | string, curr: Image | string) => {
            if (typeof acc === 'string' || !acc.width) {
              return curr;
            }

            if (typeof curr === 'string' || !curr.width) {
              return acc;
            }

            return curr.width > acc.width ? curr : acc;
          },
          ''
        );

        if (typeof biggest === 'string') {
          props = this.stringProps(biggest);
        } else {
          props = {
            ...this.imageProps(biggest),
            srcset: this.image
              .filter((img: Image | string) => typeof img !== 'string')
              .filter((img: Image) => img.width)
              .map((img: Image) => {
                return `${this.usePath ? img.path : img.cachedPath} ${img.width}w`;
              })
              .join(', '),
          };
        }
      } else {
        props = this.stringProps(undefined);
      }
    } else {
      props = this.imageProps(this.image);
    }

    // We check the difs to avoid flicker in case the ref change but the image stay the same
    if (
      props.src !== this.props?.src ||
      props.alt !== this.props?.alt ||
      props.title !== this.props?.title ||
      props.srcset !== this.props?.srcset
    ) {
      this.props = props;

      // We reset the loading state if the source change
      if (props.src !== this.props?.src) {
        this.loaded = false;
      }
    }
  }

  private imageProps(image?: Image): ImageProps {
    return {
      src: (this.usePath ? image?.path : image?.cachedPath) ?? '',
      alt: this.alt ?? image?.alt,
      title: this.title ?? image?.title,
    };
  }

  private stringProps(image?: string): ImageProps {
    return {
      src: image ?? '',
      alt: this.alt,
      title: this.title,
    };
  }
}
