import { Injectable } from '@angular/core';
import {
  catchError,
  concat,
  last,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import {
  CartCtaDialogComponent,
  CartCtaDialogData,
} from '../components/dialog/cart-cta-dialog/cart-cta-dialog.component';
import { OrderCheckoutStatusEnum } from '../enums/order/order-checkout-status.enum';
import { Order, OrderItem } from '../models/dto/cart/order';
import { Variant } from '../models/dto/catalog/variant';
import { AppDialogService } from './app-dialog.service';
import { AppStateService } from './app-state.service';
import { OrderHttpService } from './http/order-http.service';
import { TrackerService } from './tms/tracker.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  public constructor(
    private readonly _appState: AppStateService,
    private readonly _appDialog: AppDialogService,
    private readonly _orderHttp: OrderHttpService,
    private readonly _tracker: TrackerService
  ) {}

  private get cart$(): Observable<Order | null> {
    let cart$: Observable<Order | null>;
    if (this._appState.cart) {
      cart$ = this.clearPromo$;
    } else {
      cart$ = this._orderHttp.create().pipe(
        tap((cart: Order | null) => {
          this._appState.cart = cart ?? undefined;
        }),
        switchMap(() => this.clearPromo$)
      );
    }

    return cart$;
  }

  private get clearPromo$(): Observable<Order | null> {
    let cart$: Observable<Order | null>;
    if (this._appState.cart) {
      if (this._appState.cart.couponCode) {
        cart$ = this._orderHttp
          .update(
            this._appState.cart.tokenValue ?? '',
            this._appState.cart.email ?? '',
            this._appState.cart.shippingAddress,
            this._appState.cart.billingAddress
          )
          .pipe(
            tap((cart: Order | null) => {
              this._appState.cart = cart ?? undefined;
            })
          );
      } else {
        cart$ = of(this._appState.cart);
      }
    } else {
      cart$ = of(null);
    }

    return cart$;
  }

  public addItems(
    variantsCodes: string[],
    openCart?: boolean,
    suggestions?: Variant[]
  ): Observable<Order | null> {
    return this.cart$.pipe(
      switchMap((cart: Order | null) => {
        if (cart?.tokenValue) {
          const token: string = cart.tokenValue;
          const requests: Observable<Order | null>[] = variantsCodes.map(
            (variantCode: string) => this._orderHttp.addItem(variantCode, token)
          );

          return concat(...requests).pipe(
            last(),
            tap((cart: Order | null) => {
              this._appState.cart = cart ?? undefined;
              const items: OrderItem[] =
                cart?.items?.filter(
                  (i: OrderItem) =>
                    i.variant?.code && !!variantsCodes.includes(i.variant?.code)
                ) ?? [];

              if (openCart && items.length) {
                this._appDialog.openPanel<
                  void,
                  CartCtaDialogData,
                  CartCtaDialogComponent
                >(CartCtaDialogComponent, {
                  items,
                  suggestions,
                });
              }

              if (items && cart) {
                items.forEach((item: OrderItem) => {
                  this._tracker.trackCartUpdate(item, 1, cart);
                });
              }
            })
          );
        } else {
          return throwError(
            () => 'Error while adding a cart item (no cart found)'
          );
        }
      })
    );
  }

  public setQuantity(quantity: number, id: number): Observable<Order | null> {
    let update: number = 0;
    let item: OrderItem | undefined;
    return this.cart$.pipe(
      switchMap((cart: Order | null) => {
        if (cart?.tokenValue) {
          item = cart.items?.find((item: OrderItem) => item.id === id);
          if (!item) {
            return throwError(
              () => 'Error while setting a cart item quantity (no item in cart)'
            );
          } else {
            update = quantity - (item.quantity ?? 0);
          }

          if (quantity) {
            return this._orderHttp
              .updateQuantity(quantity, id.toString(), cart.tokenValue)
              .pipe(
                tap((cart: Order | null) => {
                  this._appState.cart = cart ?? undefined;
                })
              );
          } else {
            return this._orderHttp
              .removeItem(id.toString(), cart.tokenValue)
              .pipe(switchMap(() => this.reload()));
          }
        } else {
          return throwError(
            () => 'Error while setting a cart item quantity (no cart found)'
          );
        }
      }),
      tap((cart: Order | null) => {
        if (item && cart) {
          this._tracker.trackCartUpdate(item, update, cart);
        }
      }),
      catchError((error: unknown) =>
        this.reload().pipe(
          switchMap(() => {
            return throwError(() => error);
          })
        )
      )
    );
  }

  public reload(): Observable<Order | null> {
    if (!this._appState.cartToken) {
      return of(null);
    }

    return this._orderHttp.find(this._appState.cartToken).pipe(
      tap((cart: Order | null) => {
        this._appState.cart = cart ?? undefined;
      })
    );
  }

  public informationStepCompleted(cart: Order): boolean {
    return (
      !!cart.checkoutState &&
      [
        OrderCheckoutStatusEnum.addressed,
        OrderCheckoutStatusEnum.shipping_selected,
        OrderCheckoutStatusEnum.shippping_skipped,
        OrderCheckoutStatusEnum.payment_selected,
        OrderCheckoutStatusEnum.payment_skipped,
      ].includes(cart.checkoutState)
    );
  }

  public shippingStepCompleted(cart: Order): boolean {
    return (
      !!cart.checkoutState &&
      [
        OrderCheckoutStatusEnum.shipping_selected,
        OrderCheckoutStatusEnum.shippping_skipped,
        OrderCheckoutStatusEnum.payment_selected,
        OrderCheckoutStatusEnum.payment_skipped,
      ].includes(cart.checkoutState)
    );
  }

  public paymentStepCompleted(cart: Order): boolean {
    return (
      !!cart.checkoutState &&
      [
        OrderCheckoutStatusEnum.payment_selected,
        OrderCheckoutStatusEnum.payment_skipped,
      ].includes(cart.checkoutState)
    );
  }

  public getOutOfStocks(cart: Order): OrderItem[] {
    return (cart.items ?? []).filter((o: OrderItem) => {
      return (o.quantity ?? 0) > (o.variant?.stock ?? 0);
    });
  }

  public valid(cart: Order): boolean {
    return cart.checkoutState !== OrderCheckoutStatusEnum.completed;
  }
}
