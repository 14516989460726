import { FamilyCodes } from '../enums/api/family-codes.enum';
import { Locale } from '../enums/i18n/locale.enum';
import { CookieOptions } from '../models/sys/cookies';

export abstract class Settings {
  public static readonly i18nPath: string = '/assets/i18n/';

  public static readonly tmpStorageExpiration: number = 3600;

  public static readonly defaultLocale: Locale = Locale.int;
  public static readonly defaultHrefLang: Locale = Locale.fr;
  public static readonly defaultPagination: number = 30;
  public static readonly defaultSnackDuration: number = 10000;

  public static readonly privacy: string = 'privacy@mavic.com';

  public static readonly passwordMinLength: number = 8;
  public static readonly passwordHasNumber: boolean = true;
  public static readonly passwordHasUpperCase: boolean = true;
  public static readonly passwordHasLowerCase: boolean = true;
  public static readonly passwordHasSpecialCharacters: boolean = false;

  public static readonly stockAvailableLimit: string = '2060-12-31 00:00:00';

  public static readonly excludeSales: FamilyCodes[] = [
    FamilyCodes.mja,
    FamilyCodes.msp,
    FamilyCodes.mmo,
  ];

  public static readonly variantsSelector: FamilyCodes[] = [FamilyCodes.mss];

  public static readonly cookieOptions: CookieOptions = {
    secure: true,
    httpOnly: false,
    sameSite: 'Strict',
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  };
}
