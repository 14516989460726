import { Inject, Injectable, Optional } from '@angular/core';
import { RESPONSE } from '../../../../express.tokens';
import { Response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class SsrResponseService {
  public constructor(
    @Optional() @Inject(RESPONSE) private readonly _response: Response
  ) {}

  public setStatus(code: number, message?: string): void {
    if (this._response) {
      this._response.statusCode = code;
      if (message) {
        this._response.statusMessage = message;
      }
    }
  }

  public setNotFound(message: string = 'Not found'): void {
    this.setStatus(404, message);
  }

  public setInternalError(message: string = 'Internal error'): void {
    this.setStatus(500, message);
  }
}
