import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AppRouterService } from '../app-router.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AppStateService } from '../app-state.service';
import { Locale } from '../../enums/i18n/locale.enum';
import { AlternateLink } from '../../models/sys/metadata';
import { Settings } from '../../constants/settings.constant';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  public constructor(
    private readonly _appRouter: AppRouterService,
    private readonly _appState: AppStateService,
    private readonly _title: Title,
    private readonly _meta: Meta,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public set title(value: string | undefined) {
    this._title.setTitle(value ?? 'Mavic');

    if (value) {
      this._meta.updateTag({ name: 'og:title', content: value });
    } else {
      this._meta.removeTag('name="og:title"');
    }
  }

  public set description(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'description', content: value });
      this._meta.updateTag({ name: 'og:description', content: value });
    } else {
      this._meta.removeTag('name="description"');
      this._meta.removeTag('name="og:description"');
    }
  }

  public set keywords(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'keywords', content: value });
    } else {
      this._meta.removeTag('name="keywords"');
    }
  }

  public set author(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'author', content: value });
    } else {
      this._meta.removeTag('name="author"');
    }
  }

  public set image(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'og:image', content: value });
    } else {
      this._meta.removeTag('name="og:image"');
    }
  }

  public set robots(value: string | undefined) {
    if (value) {
      this._meta.updateTag({ name: 'robots', content: value });
    } else {
      this._meta.removeTag('name="robots"');
    }
  }

  public set lang(value: Locale) {
    this._document.documentElement.lang = value;
    this._meta.updateTag({ name: 'og:locale', content: value });
  }

  public bootstrap(): void {
    this._appState.locale$.subscribe((locale: Locale) => {
      this.lang = locale;
    });

    this._meta.updateTag({ name: 'og:type', value: 'website' });
    this._meta.updateTag({ name: 'og:site_name', value: 'Mavic' });
  }

  public setCanonicalURL(href?: string): void {
    href = environment.baseUrl + (href ?? this._appRouter.route);
    let link: HTMLLinkElement = this._document.head.querySelectorAll(
      'link[rel="canonical"]'
    )?.[0] as HTMLLinkElement;

    // If the tag do not exists, create one
    if (!link) {
      link = this._document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this._document.head.appendChild(link);
    }

    // Set the canonical url with the current url but remove the query params
    link.setAttribute('href', href);

    // Set the og url
    this._meta.updateTag({ name: 'og:url', value: href });
  }

  public setAlternateLinks(urls: AlternateLink[]): void {
    const links: NodeListOf<HTMLLinkElement> =
      this._document.head.querySelectorAll('link[rel="alternate"]');

    links?.forEach((link: HTMLLinkElement) =>
      link.parentNode?.removeChild(link)
    );

    urls.forEach((alt: AlternateLink) => {
      const link: HTMLLinkElement = this._document.createElement('link');
      link.setAttribute('rel', 'alternate');
      link.setAttribute('href', environment.baseUrl + alt.href);
      link.setAttribute('hreflang', alt.lang);
      this._document.head.appendChild(link);

      if (alt.lang === Settings.defaultHrefLang) {
        const xDefault: HTMLLinkElement = this._document.createElement('link');
        xDefault.setAttribute('rel', 'alternate');
        xDefault.setAttribute('href', environment.baseUrl + alt.href);
        xDefault.setAttribute('hreflang', 'x-default');
        this._document.head.appendChild(xDefault);
      }
    });
  }

  public clear(): void {
    this.title = undefined;
    this.description = undefined;
    this.keywords = undefined;
    this.author = undefined;
    this.image = undefined;
    this.robots = undefined;
    this.setAlternateLinks([]);
  }
}
