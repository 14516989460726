import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'promo',
})
export class PromoPipe implements PipeTransform {
  public transform(price?: number, original?: number): string {
    return price && original
      ? -(100 - Math.round((price / original) * 100)) + '%'
      : '--';
  }
}
