export enum TrackEvents {
  // PageView
  pageView = 'PageView',
  // E-Commerce
  preload = 'virtualPageview',
  taxon = 'viewCategory',
  taxonFilters = 'selectFilters',
  taxonProduct = 'selectProduct',
  product = 'viewProduct',
  zoom = 'zoomProduct',
  addToCart = 'addToCart',
  removeFromCart = 'removeFromCart',
  viewCart = 'viewCart',
  findRetailer = 'findRetailer',
  checkout = 'beginCheckout',
  checkoutInfos = 'shippingInfos',
  checkoutShipping = 'shippingMethod',
  checkoutPayment = 'paymentInfos',
  purchase = 'purchase',
  // Site
  universe = 'selectUnivers',
  homeSelection = 'productSelection',
  productSearch = 'searchProduct',
  dealerSearch = 'searchFindDealer',
  footerLinks = 'quickLinks',
  // Touchpoints
  newsletter = 'newsletter',
  register = 'sign_up',
  login = 'login',
  social = 'socialMedia',
}
