import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Inject,
  PLATFORM_ID,
  StateKey,
  TransferState,
  makeStateKey,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { TransferStateKeys } from '../../enums/transfer-state-keys.enum';
import { RequestParams } from '../../models/sys/routing';
import { AbstractHttpService, Response } from './abstract-http.service';

export abstract class SsrHttpService extends AbstractHttpService {
  protected static readonly transferPrefix: string = 'http-get-';

  protected constructor(
    @Inject(PLATFORM_ID) private readonly _platformId: object,
    protected readonly _transferState: TransferState,
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * JSON get call override using transfer state
   */
  protected override get<T>(
    path: string,
    options: {
      response: Response.json | Response.vnd;
      params?: RequestParams;
      attrs?: RequestParams;
      api?: string;
      language?: string;
      authorization?: string;
      transferStateKey?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | null>;

  /**
   * Blob get call override (no transfer state)
   */
  protected override get(
    path: string,
    options: {
      response: Response.blob;
      params?: RequestParams;
      attrs?: RequestParams;
      language?: string;
      authorization?: string;
      api?: string;
    }
  ): Observable<Blob | null>;

  /**
   * Get call override using transfer state
   * When overriding an overloaded method, you must re-define the main implementation and the different signatures
   */
  protected override get<T>(
    path: string,
    options: {
      response: Response;
      params?: RequestParams;
      attrs?: RequestParams;
      language?: string;
      authorization?: string;
      api?: string;
      transferStateKey?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | Blob | null> {
    if (options.response === Response.blob) {
      // We obviously don't use transfer state for blob
      return super.get(path, {
        response: Response.blob,
        params: options.params,
        attrs: options.attrs,
        language: options.language,
        authorization: options.authorization,
        api: options.api,
      });
    } else {
      const key: StateKey<unknown> = makeStateKey<unknown>(
        options?.transferStateKey ??
          TransferStateKeys.http + path + JSON.stringify(options)
      );

      // Checking if transfer state has data associated with our key
      if (this._transferState.hasKey(key)) {
        const data: unknown = this._transferState.get<unknown>(key, null);
        this._transferState.remove(key);

        return of(mapper ? mapper(data) : (data as T | null));
      } else {
        return super.get<T>(
          path,
          {
            response:
              options.response === Response.json ? Response.json : Response.vnd,
            params: options.params,
            attrs: options.attrs,
            language: options.language,
            authorization: options.authorization,
            api: options.api,
          },
          (data: unknown) => {
            if (isPlatformServer(this._platformId)) {
              // We store the result in the transfer state
              this._transferState.set(key, data);
            }

            return mapper ? mapper(data) : (data as T | null);
          }
        );
      }
    }
  }
}
