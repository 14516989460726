import { Component, Input, OnChanges } from '@angular/core';
import { contrast } from 'src/app/shared/utils/color.utils';

export type ButtonColor =
  | 'yellow'
  | 'white'
  | 'grey'
  | 'dark-grey'
  | 'black'
  | 'transparent'
  | 'override';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'a[app-button], button[app-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input() public color?: ButtonColor;
  @Input() public condensed?: boolean;

  @Input() public overrideBgColor?: string;
  public overrideTextColor?: string;

  public ngOnChanges(): void {
    if (this.color === 'override' && this.overrideBgColor) {
      this.overrideTextColor = contrast(this.overrideBgColor);
    } else {
      this.overrideTextColor = undefined;
    }
  }
}
