@if (props) {
  <img
    [src]="props.src"
    [alt]="props.alt ?? ''"
    [title]="props.title ?? ''"
    [srcset]="props.srcset ?? ''"
    loading="lazy"
    [draggable]="draggable"
  [ngClass]="{
    loaded,
    'guess-height': guessHeight,
    'fit': display === 'fit',
    'cover': display === 'cover'
  }"
  [ngStyle]="{
    maxWidth,
    maxHeight
  }"
    (load)="loaded = true"
    />
}
