export const safeDecodeURIComponent = (str?: string | null): string => {
  try {
    return decodeURIComponent(str ?? '');
  } catch (e: unknown) {
    return str ?? '';
  }
};

export const safeParseJSON = (str?: string | null): unknown => {
  try {
    return JSON.parse(str ?? '');
  } catch (e: unknown) {
    return undefined;
  }
};
