export enum FamilyCodes {
  maa = 'maa', // Textile
  maf = 'maf', // Footwear
  mag = 'mag', // Accessoire
  mhe = 'mhe', // Casque
  mja = 'mja', // Jante
  mmo = 'mmo', // Moyeux
  mro = 'mro', // Roue
  msp = 'msp', // Pièces détachées
  mco = 'mco', // Systems & Accessories
  mty = 'mty', // Pneu
  mmw = 'mmw', // Merchandising
  mss = 'mss', // ???
}
