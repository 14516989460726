import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

@Directive({
  selector: '[appClickAway]',
})
export class ClickAwayDirective implements OnInit, OnDestroy {
  @Input() public appClickAwayWhitelist?: ElementRef | HTMLElement;

  @Output() public appClickAway: EventEmitter<UIEvent> =
    new EventEmitter<UIEvent>();

  public constructor(
    private readonly _element: ElementRef,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  /**
   * Init instantiate the doc listener (on timeout to avoid the initial trigger if the attached element apparition was due to a click event)
   */
  public ngOnInit(): void {
    setTimeout(() => {
      this.onDocumentClick = this.onDocumentClick.bind(this);
      this._document.addEventListener('click', this.onDocumentClick);
      this._document.addEventListener('focusin', this.onDocumentClick);
    });
  }

  /**
   * Destroy cleans up listener
   */
  public ngOnDestroy(): void {
    this._document.removeEventListener('click', this.onDocumentClick);
    this._document.removeEventListener('focusin', this.onDocumentClick);
  }

  /**
   * Document click handler
   */
  private onDocumentClick(e: UIEvent): void {
    if (
      this._element?.nativeElement &&
      this._element?.nativeElement !== e.target &&
      !this._element.nativeElement.contains(e.target) &&
      !this.whitelist?.contains(e.target as Node)
    ) {
      this.appClickAway.emit(e);
    }
  }

  private get whitelist(): HTMLElement | undefined {
    let element: HTMLElement | undefined;
    if (this.appClickAwayWhitelist) {
      element =
        this.appClickAwayWhitelist instanceof ElementRef
          ? this.appClickAwayWhitelist.nativeElement
          : this.appClickAwayWhitelist;
    }

    return element;
  }
}
