@if (variant) {
  <article
    [ngClass]="{ grey }"
    >
    <a [routerLink]="routes.product | route: { slug: variant.slug }">
      @if (variant.images && variant.images.length) {
        <app-image
          class="image"
          [image]="variant.images[0]"
          display="fit"
        ></app-image>
      }
    </a>
    <div class="inner">
      <header>
        <span>{{ variant.title }}</span>
        <app-price
          [price]="variant.price"
          [reverse]="true"
        ></app-price>
      </header>
      <a
        app-button
        color="yellow"
        class="button"
        [routerLink]="routes.product | route: { slug: variant.slug }"
        [condensed]="true"
      >{{ 'Voir' | translate | async }}</a>
    </div>
  </article>
}
