import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap, tap, throwError } from 'rxjs';
import { Order } from '../models/dto/cart/order';
import { Customer } from '../models/dto/account/customer';
import { Token } from '../models/dto/token';
import { AppStateService } from './app-state.service';
import { CartService } from './cart.service';
import { AuthHttpService } from './http/auth-http.service';
import { CustomerHttpService } from './http/customer-http.service';
import { OrderHttpService } from './http/order-http.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public constructor(
    private readonly _appState: AppStateService,
    private readonly _authHttp: AuthHttpService,
    private readonly _customerHttp: CustomerHttpService,
    private readonly _orderHttp: OrderHttpService,
    private readonly _cartService: CartService
  ) {}

  /**
   * User login process (followed by the bootstrap)
   */
  public login(email: string, password: string): Observable<void> {
    return this._authHttp.auth(email, password).pipe(
      tap((token: Token | null) => {
        this._appState.customerToken = token ?? undefined;
      }),
      switchMap(() => this.bootstrapCustomer())
    );
  }

  /**
   * Standalone bootstrap
   */
  public bootstrapCustomer(): Observable<void> {
    if (!this._appState.customerToken) {
      this._appState.logout();
      return this.bootstrapCart();
    }

    return this._customerHttp.findByToken(this._appState.customerToken).pipe(
      tap((customer: Customer | null) => {
        if (customer) {
          this._appState.customer = customer;
          if (!this._appState.cartToken) {
            this._appState.cartToken = customer.last_cart;
          }
        } else {
          this._appState.logout();
          throwError(() => '');
        }
      }),
      switchMap(() => this.bootstrapCart()),
      catchError((error: unknown) => {
        this._appState.logout();
        return throwError(() => error);
      })
    );
  }

  /**
   * Specific cart bootstrap
   */
  public bootstrapCart(): Observable<void> {
    if (!this._appState.cartToken) {
      this._appState.cart = undefined;
      return of(undefined);
    }

    return this._orderHttp.find(this._appState.cartToken).pipe(
      tap((cart: Order | null) => {
        if (cart && this._cartService.valid(cart)) {
          this._appState.cart = cart;
        } else {
          this._appState.cartToken = undefined;
          this._appState.cart = undefined;
          throwError(() => '');
        }
      }),
      switchMap(() => of(undefined)),
      catchError((error: unknown) => {
        this._appState.cartToken = undefined;
        this._appState.cart = undefined;
        return of(undefined);
      })
    );
  }
}
