import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { Observable } from 'rxjs';
import { Channel, ChannelCode } from '../../models/dto/channel';
import { Response } from './abstract-http.service';
import { SsrHttpService } from './ssr-http.service';
import { Webservices } from '../../constants/routes/webservices.constant';
import { SyliusLocale } from '../../enums/i18n/locale.enum';

@Injectable({
  providedIn: 'root',
})
export class ChannelHttpService extends SsrHttpService {
  public constructor(
    @Inject(PLATFORM_ID) platformId: object,
    transferState: TransferState,
    http: HttpClient
  ) {
    super(platformId, transferState, http);
  }

  public channel(
    code: ChannelCode,
    locale: SyliusLocale
  ): Observable<Channel | null> {
    return this.get<Channel>(Webservices.channel, {
      attrs: { code },
      params: { locale },
      response: Response.json,
    });
  }
}
