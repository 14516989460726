import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RequestParams } from '../../models/sys/routing';

export enum Response {
  json = 'json',
  vnd = 'vnd',
  blob = 'blob',
}

export enum ContentType {
  none = '',
  json = 'application/json',
  vnd = 'application/vnd.api+json',
  mergeJson = 'application/merge-patch+json',
}

export abstract class AbstractHttpService {
  protected _defaultApi: string = '';

  /**
   * Abstract REST call service to implement
   */
  protected constructor(protected _http: HttpClient) {
    this._defaultApi = environment.apiProtocol + environment.apiHost;
  }

  public get defaultApi(): string {
    return this._defaultApi;
  }

  /**
   * Headers setup
   */
  protected headers(
    content: ContentType = ContentType.json,
    accept: ContentType = ContentType.json,
    language?: string,
    authorization?: string
  ): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    if (content) {
      headers = headers.set('Content-Type', content);
    }

    if (accept) {
      headers = headers.set('Accept', accept);
    }

    if (language) {
      headers = headers.set('Accept-Language', language);
    }

    if (authorization) {
      headers = headers.set('Authorization', authorization);
    }

    return headers;
  }

  /**
   * Options setup
   */
  protected options(
    contentType: ContentType,
    accept?: ContentType,
    language?: string,
    authorization?: string
  ): {
    headers: HttpHeaders;
    observe: 'response';
  } {
    return {
      headers: this.headers(contentType, accept, language, authorization),
      // withCredentials: false,
      observe: 'response',
    };
  }

  /**
   * Get JSON call
   */
  protected get<T>(
    path: string,
    options: {
      response: Response.json | Response.vnd;
      params?: RequestParams;
      attrs?: RequestParams;
      language?: string;
      authorization?: string;
      api?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | null>;

  /**
   * Get download call
   */
  protected get(
    path: string,
    options: {
      response: Response.blob;
      params?: RequestParams;
      attrs?: RequestParams;
      language?: string;
      authorization?: string;
      api?: string;
    }
  ): Observable<Blob | null>;

  /**
   * Get call
   * When overloading a method, you specify the different signatures but you only define the implementation once
   */
  protected get<T>(
    path: string,
    options: {
      response: Response;
      params?: RequestParams;
      attrs?: RequestParams;
      language?: string;
      authorization?: string;
      api?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | Blob | null> {
    options = options || {};
    const url: string = this.buildUrl(
      path,
      options.params,
      options.attrs,
      options.api
    );

    const request: Observable<HttpResponse<T | Blob>> =
      options.response === Response.json || options.response === Response.vnd
        ? this._http.get<T>(url, {
            ...this.options(
              ContentType.none,
              options.response === Response.json
                ? ContentType.json
                : ContentType.vnd,
              options.language,
              options.authorization
            ),
            responseType: Response.json,
          })
        : this._http.get(url, {
            ...this.options(
              ContentType.none,
              ContentType.none,
              options.language,
              options.authorization
            ),
            responseType: Response.blob,
          });

    return request.pipe(
      map((response: HttpResponse<T | Blob>) =>
        mapper ? mapper(response.body) : response.body
      )
    );
  }

  /**
   * Post JSON call
   */
  protected post<T>(
    path: string,
    options: {
      response: Response.json | Response.vnd;
      params?: RequestParams;
      attrs?: RequestParams;
      data?: unknown;
      language?: string;
      authorization?: string;
      api?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | null>;

  /**
   * Post download call
   */
  protected post(
    path: string,
    options: {
      response: Response.blob;
      params?: RequestParams;
      attrs?: RequestParams;
      data?: unknown;
      language?: string;
      authorization?: string;
      api?: string;
    }
  ): Observable<Blob | null>;

  /**
   * Post call
   * When overloading a method, you specify the different signatures but you only define the implementation once
   */
  protected post<T>(
    path: string,
    options: {
      response: Response;
      params?: RequestParams;
      attrs?: RequestParams;
      data?: unknown;
      language?: string;
      authorization?: string;
      api?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | Blob | null> {
    options = options || {};
    const url: string = this.buildUrl(
      path,
      options.params,
      options.attrs,
      options.api
    );

    const request: Observable<HttpResponse<T | Blob>> =
      options.response === Response.json || options.response === Response.vnd
        ? this._http.post<T>(url, options.data, {
            ...this.options(
              options.data instanceof FormData
                ? ContentType.none
                : ContentType.json,
              options.response === Response.json
                ? ContentType.json
                : ContentType.vnd,
              options.language,
              options.authorization
            ),
            responseType: Response.json,
          })
        : this._http.post(url, options.data, {
            ...this.options(
              options.data instanceof FormData
                ? ContentType.none
                : ContentType.json,
              ContentType.none,
              options.language,
              options.authorization
            ),
            responseType: Response.blob,
          });

    return request.pipe(
      map((response: HttpResponse<T | Blob>) =>
        mapper ? mapper(response.body) : response.body
      )
    );
  }

  /**
   * Put JSON call
   */
  protected put<T>(
    path: string,
    options: {
      response: Response.json | Response.vnd;
      params?: RequestParams;
      attrs?: RequestParams;
      data?: unknown;
      language?: string;
      authorization?: string;
      api?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | null>;

  /**
   * Put download call
   */
  protected put(
    path: string,
    options: {
      response: Response.blob;
      params?: RequestParams;
      attrs?: RequestParams;
      data?: unknown;
      language?: string;
      authorization?: string;
      api?: string;
    }
  ): Observable<Blob | null>;

  /**
   * Put call
   * When overloading a method, you specify the different signatures but you only define the implementation once
   */
  protected put<T>(
    path: string,
    options: {
      response: Response;
      params?: RequestParams;
      attrs?: RequestParams;
      data?: unknown;
      language?: string;
      authorization?: string;
      api?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | Blob | null> {
    options = options || {};
    const url: string = this.buildUrl(
      path,
      options.params,
      options.attrs,
      options.api
    );

    const request: Observable<HttpResponse<T | Blob>> =
      options.response === Response.json || options.response === Response.vnd
        ? this._http.put<T>(url, options.data, {
            ...this.options(
              options.data instanceof FormData
                ? ContentType.none
                : ContentType.json,
              options.response === Response.json
                ? ContentType.json
                : ContentType.vnd,
              options.language,
              options.authorization
            ),
            responseType: Response.json,
          })
        : this._http.put(url, options.data, {
            ...this.options(
              options.data instanceof FormData
                ? ContentType.none
                : ContentType.json,
              ContentType.none,
              options.language,
              options.authorization
            ),
            responseType: Response.blob,
          });

    return request.pipe(
      map((response: HttpResponse<T | Blob>) =>
        mapper ? mapper(response.body) : response.body
      )
    );
  }

  /**
   * Patch JSON call
   */
  protected patch<T>(
    path: string,
    options: {
      response: Response.json | Response.vnd;
      params?: RequestParams;
      attrs?: RequestParams;
      data?: unknown;
      language?: string;
      authorization?: string;
      api?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | null>;

  /**
   * Patch download call
   */
  protected patch(
    path: string,
    options: {
      response: Response.blob;
      params?: RequestParams;
      attrs?: RequestParams;
      data?: unknown;
      language?: string;
      authorization?: string;
      api?: string;
    }
  ): Observable<Blob | null>;

  /**
   * Patch call
   * When overloading a method, you specify the different signatures but you only define the implementation once
   */
  protected patch<T>(
    path: string,
    options: {
      response: Response;
      params?: RequestParams;
      attrs?: RequestParams;
      data?: unknown;
      language?: string;
      authorization?: string;
      api?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | Blob | null> {
    options = options || {};
    const url: string = this.buildUrl(
      path,
      options.params,
      options.attrs,
      options.api
    );

    const request: Observable<HttpResponse<T | Blob>> =
      options.response === Response.json || options.response === Response.vnd
        ? this._http.patch<T>(url, options.data, {
            ...this.options(
              options.data instanceof FormData
                ? ContentType.none
                : ContentType.mergeJson,
              options.response === Response.json
                ? ContentType.json
                : ContentType.vnd,
              options.language,
              options.authorization
            ),
            responseType: Response.json,
          })
        : this._http.patch(url, options.data, {
            ...this.options(
              options.data instanceof FormData
                ? ContentType.none
                : ContentType.mergeJson,
              ContentType.none,
              options.language,
              options.authorization
            ),
            responseType: Response.blob,
          });

    return request.pipe(
      map((response: HttpResponse<T | Blob>) =>
        mapper ? mapper(response.body) : response.body
      )
    );
  }

  /**
   * Delete call
   */
  protected delete<T>(
    path: string,
    options: {
      response: Response;
      params?: RequestParams;
      attrs?: RequestParams;
      language?: string;
      authorization?: string;
      api?: string;
    },
    mapper?: (body: unknown) => T | null
  ): Observable<T | null> {
    options = options || {};
    const url: string = this.buildUrl(
      path,
      options.params,
      options.attrs,
      options.api
    );

    const request: Observable<HttpResponse<T>> = this._http.delete<T>(url, {
      ...this.options(
        ContentType.json,
        ContentType.json,
        options.language,
        options.authorization
      ),
      responseType: Response.json,
    });

    return request.pipe(
      map((response: HttpResponse<T>) =>
        mapper ? mapper(response.body) : response.body
      )
    );
  }

  /**
   * Builds an URL
   */
  public buildUrl(
    path: string,
    params?: RequestParams,
    attrs?: RequestParams,
    api?: string
  ): string {
    return (
      (api ?? this._defaultApi) +
      this.mapParameters(this.mapAttributes(path, attrs), params)
    );
  }

  /**
   * Url attributes mapping
   */
  protected mapAttributes(path: string, attrs?: RequestParams): string {
    if (attrs) {
      for (const key in attrs) {
        if (Object.getOwnPropertyDescriptor(attrs, key)) {
          path = path.replace(':' + key, attrs[key] as string);
        }
      }
    }

    return path;
  }

  /**
   * Url parameters mapping
   */
  protected mapParameters(path: string, params?: RequestParams): string {
    if (params) {
      let httpParams: HttpParams = new HttpParams();
      Object.keys(params).forEach((key: string) => {
        if (params[key] !== undefined) {
          if (Array.isArray(params[key])) {
            (params[key] as []).forEach((item: unknown) => {
              httpParams = httpParams.append(key + '[]', this.mapValue(item));
            });
          } else {
            httpParams = httpParams.append(key, this.mapValue(params[key]));
          }
        }
      });

      path = this.concatParams(path, httpParams);
    }

    return path;
  }

  /**
   * Url parameter value mapping
   */
  protected mapValue(value: unknown): number | string | boolean {
    switch (typeof value) {
      case 'boolean':
      case 'number':
      case 'string':
        return value;
      default:
        return JSON.stringify(value);
    }
  }

  /**
   * Concatenate HttpParams to route
   */
  protected concatParams(path: string, httpParams: HttpParams): string {
    const paramInfo: string = httpParams.toString();
    if (paramInfo) {
      path += '?' + paramInfo;
    }

    return path;
  }
}
