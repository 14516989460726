import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { Observable } from 'rxjs';
import { TransferStateKeys } from 'src/app/shared/enums/transfer-state-keys.enum';
import { environment } from 'src/environments/environment';
import { Settings } from '../../../constants/settings.constant';
import { SsrHttpService } from '../../http/ssr-http.service';
import { TranslationLoader } from './translation-loader';
import { Response } from '../../http/abstract-http.service';
import { Translations } from '../translate.service';

@Injectable({
  providedIn: 'root',
})
export class HttpTranslationLoaderService
  extends SsrHttpService
  implements TranslationLoader
{
  public constructor(
    @Inject(PLATFORM_ID) platformId: object,
    transferState: TransferState,
    http: HttpClient
  ) {
    super(platformId, transferState, http);
  }

  /**
   * Basic http loading from settings
   */
  public load(lang: string): Observable<Translations | null> {
    return this.get<Translations>(`${Settings.i18nPath}${lang}.json`, {
      response: Response.json,
      api: environment.baseUrl,
      transferStateKey: `${TransferStateKeys.i18n}-${lang}`,
    });
  }
}
