import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { Observable } from 'rxjs';
import { Webservices } from '../../constants/routes/webservices.constant';
import { Address } from '../../models/dto/account/address';
import { Order } from '../../models/dto/cart/order';
import { PickupPlace } from '../../models/dto/cart/pickup-place';
import { Response } from './abstract-http.service';
import { SsrHttpService } from './ssr-http.service';

@Injectable({
  providedIn: 'root',
})
export class OrderHttpService extends SsrHttpService {
  public constructor(
    @Inject(PLATFORM_ID) platformId: object,
    transferState: TransferState,
    http: HttpClient
  ) {
    super(platformId, transferState, http);
  }

  public find(token: string): Observable<Order | null> {
    return this.get<Order>(Webservices.order, {
      attrs: { token },
      response: Response.json,
    });
  }

  public findAll(): Observable<Order[] | null> {
    return this.get<Order[]>(Webservices.orders, {
      response: Response.json,
    });
  }

  public create(): Observable<Order | null> {
    return this.post<Order>(Webservices.orders, {
      response: Response.json,
      data: {},
    });
  }

  public remove(token: string): Observable<void | null> {
    return this.delete<void>(Webservices.order, {
      attrs: { token },
      response: Response.json,
    });
  }

  public addItem(variantCode: string, token: string): Observable<Order | null> {
    return this.post<Order>(Webservices.orderItems, {
      attrs: { token },
      data: {
        productVariant: variantCode,
        quantity: 1,
      },
      response: Response.json,
    });
  }

  public removeItem(id: string, token: string): Observable<void | null> {
    return this.delete<void>(Webservices.orderItem, {
      attrs: { token, id },
      response: Response.json,
    });
  }

  public updateQuantity(
    quantity: number,
    id: string,
    token: string
  ): Observable<Order | null> {
    return this.patch<Order>(Webservices.orderItem, {
      attrs: { token, id },
      data: {
        quantity,
      },
      response: Response.json,
    });
  }

  public update(
    tokenOrder: string,
    email: string,
    shippingAddress?: Address,
    billingAddress?: Address,
    couponCode?: string
  ): Observable<Order | null> {
    return this.put<Order | null>(`${Webservices.order}`, {
      attrs: { token: tokenOrder },
      response: Response.json,
      data: {
        email,
        shippingAddress,
        billingAddress: billingAddress ?? shippingAddress,
        couponCode,
      },
    });
  }

  public setShipping(
    cartToken: string,
    shipmentId: number,
    shippingCode: string,
    pickup: PickupPlace | null
  ): Observable<Order | null> {
    return this.patch<Order | null>(Webservices.orderShipping, {
      attrs: {
        token: cartToken,
        shipmentId,
      },
      data: {
        shippingMethodCode: shippingCode,
        pickupInfo: pickup
          ? JSON.stringify({
              id: pickup.id,
              name: pickup.name,
              countryCode: pickup.countryCode,
              postCode: pickup.postcode,
              city: pickup.city,
              street: pickup.street,
            })
          : '{}',
      },
      response: Response.json,
    });
  }

  public setPayment(
    cartToken: string,
    paymentId: number,
    paymentCode: string
  ): Observable<Order | null> {
    return this.patch<Order | null>(Webservices.orderPayment, {
      attrs: {
        token: cartToken,
        paymentId,
      },
      data: {
        paymentMethodCode: paymentCode,
      },
      response: Response.json,
    });
  }

  public completeCheckout(tokenValue: string): Observable<Order | null> {
    return this.patch<Order | null>(Webservices.orderComplete, {
      attrs: {
        token: tokenValue,
      },
      data: {
        notes: '',
      },
      response: Response.json,
    });
  }
}
