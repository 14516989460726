import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '../input.component';

@Component({
  selector: 'app-number-stepper',
  templateUrl: './number-stepper.component.html',
  styleUrls: ['./number-stepper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberStepperComponent),
      multi: true,
    },
  ],
})
export class NumberStepperComponent
  extends InputComponent<number>
  implements ControlValueAccessor
{
  @Input() public min?: number = 1;
  @Input() public max?: number;
  @Input() public forceValue?: boolean = false;

  public value: number = 1;
  public disabled: boolean = false;

  public get canMinus(): boolean {
    return this.min === undefined || this.value > this.min;
  }

  public get canPlus(): boolean {
    return this.max === undefined || this.value < this.max;
  }

  public validate(value: number): boolean {
    return (
      (this.min === undefined || value >= this.min) &&
      (this.max === undefined || value <= this.max)
    );
  }

  public onMinus(): void {
    if (this.canMinus) {
      this.writeValue(
        !this.max || this.max > this.value - 1 ? this.value - 1 : this.max
      );
    }
  }

  public onPlus(): void {
    if (this.canPlus) {
      this.writeValue(this.value + 1);
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value?: number): void {
    if (
      typeof value === 'number' &&
      value !== this.value &&
      (this.validate(value) || this.forceValue)
    ) {
      this.value = value;
      this.onChange(value);
    }
  }
}
