<div
  class="wrapper"
  [appSlideToggle]="visible"
  [appSlideToggleTiming]="animation"
  >
  @if (data) {
    <article
      [ngClass]="classes"
      >
      @if (icon) {
        <app-icon
          [src]="icon"
          alt="icon"
        ></app-icon>
      }
      <p>{{data.message | translate | async }}</p>
      @if (!data.duration) {
        <button
          app-icon-button
          src="assets/images/icons/plus.svg"
          class="close"
          alt="{{ 'Fermer' | translate | async }}"
          (click)="hide()"
        ></button>
      }
    </article>
  }
</div>
