import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Price } from 'src/app/shared/models/dto/catalog/price';
import { Variant } from 'src/app/shared/models/dto/catalog/variant';
import { AbstractComponent } from '../../abstract.component';
import { Option } from 'src/app/shared/models/dto/catalog/option';
import { Bundle } from 'src/app/shared/models/dto/catalog/bundle';

@Component({
  selector: 'app-cart-item-card',
  templateUrl: './cart-item-card.component.html',
  styleUrls: ['./cart-item-card.component.scss'],
})
export class CartItemCardComponent
  extends AbstractComponent
  implements OnChanges
{
  @Input({ required: true }) public variant?: Variant;
  @Input({ required: true }) public price?: Price;

  // UI

  @Input() public disabled: boolean = false;
  @Input() public border: boolean = false;

  // Options management

  @Input() public alts?: Variant[];
  @Input() public options?: {
    [key: string]: Option[];
  };

  // Quantity management

  @Input() public quantity?: number;
  @Input() public max?: number;

  // Bundle management

  @Input() public bundle?: Bundle;

  @Output() public readonly quantityChange: EventEmitter<number>;
  @Output() public readonly variantChange: EventEmitter<Variant>;

  public current?: number;

  public constructor() {
    super();
    this.quantityChange = new EventEmitter<number>();
    this.variantChange = new EventEmitter<Variant>();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.current = this.quantity;
  }

  public setQuantity(i: number): void {
    this.current = i;
    this.quantityChange.emit(i);
  }
}
