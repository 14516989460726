import { environment } from 'src/environments/environment';
import { i18nSettings } from '../constants/i18n.constant';
import { CountryCode } from '../enums/i18n/country-code.enum';
import { Locale, SyliusLocale } from '../enums/i18n/locale.enum';
import { ChannelCode } from '../models/dto/channel';
import { AxeptioSettings, DoFinderSettings, I18N } from '../models/sys/i18n';
import { checkEnum } from './typing.utils';

export const i18nByAppLocale = (locale: Locale): I18N => {
  let settings: I18N | undefined = i18nSettings.find(
    (item: I18N) => item.appLocale === locale
  );

  if (!settings) {
    console.warn(`Locale ${locale} is not supported`);
    settings = i18nSettings[0];
  }

  return settings;
};

export const i18nBySyliusLocale = (locale: SyliusLocale): I18N => {
  let settings: I18N | undefined = i18nSettings.find(
    (item: I18N) => item.syliusLocale === locale
  );

  if (!settings) {
    console.warn(`SyliusLocale ${locale} is not supported`);
    settings = i18nSettings[0];
  }

  return settings;
};

export const appLocale = (locale: SyliusLocale): Locale => {
  return i18nBySyliusLocale(locale).appLocale;
};

export const syliusLocale = (locale: Locale): SyliusLocale => {
  return i18nByAppLocale(locale).syliusLocale;
};

export const channelCode = (locale: Locale): ChannelCode => {
  return i18nByAppLocale(locale).channelCode;
};

export const countryCode = (locale: Locale): CountryCode => {
  return i18nByAppLocale(locale).countryCode;
};

export const doFinderSettings = (locale: Locale): DoFinderSettings => {
  return environment.doFinderOverrides ?? i18nByAppLocale(locale).doFinder;
};

export const axeptioSettings = (locale: Locale): AxeptioSettings => {
  return i18nByAppLocale(locale).axeptio;
};

export const checkLocaleParam = (value?: string): boolean => {
  return checkEnum(value, Locale);
};
