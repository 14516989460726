import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Locale } from '../../enums/i18n/locale.enum';
import { AppRouterService } from '../../services/app-router.service';
import { AppStateService } from '../../services/app-state.service';
import { TranslateService } from '../../services/translations/translate.service';
import { Routes } from '../../constants/routes/routes.constant';
import { checkLocaleParam } from '../../utils/i18n.utils';

export const localeBootstrapGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Promise<boolean> | boolean => {
  if (!checkLocaleParam(route.params?.['locale'])) {
    const appRouter: AppRouterService = inject(AppRouterService);
    appRouter.navigate(Routes.notFound);

    return false;
  }

  const appState: AppStateService = inject(AppStateService);
  appState.locale = route.params?.['locale'] as Locale;

  const translate: TranslateService = inject(TranslateService);
  return translate.bootstrap().then(() => {
    return true;
  });
};
