export enum StorageKeys {
  prefix = 'mavic',
  temporary = 'tmpStorage',
  customerTokens = 'customerTokens',
  cartTokens = 'cartTokens',
  orderCompleteTokens = 'orderCompleteTokens',
  redirection = 'redirection',
  userLocale = 'userLocale',
  autoLocale = 'autoLocale',
  ackRedirect = 'ackRedirect',
  ackDelivery = 'ackDelivery',
}
