import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject, asyncScheduler, takeUntil, throttleTime } from 'rxjs';
import { getBoundingClientRect } from 'src/app/shared/utils/dom.utils';
import { AbstractComponent } from '../../abstract.component';

@Component({
  selector: 'app-panel-layout',
  templateUrl: './panel-layout.component.html',
  styleUrls: ['./panel-layout.component.scss'],
})
export class PanelLayoutComponent
  extends AbstractComponent
  implements AfterViewInit
{
  @Input() public title?: string;
  @Output() public readonly dismiss: EventEmitter<void>;

  @ViewChild('wrapper') public wrapper?: ElementRef;

  public scrollTop: boolean = false;
  public scrollBot: boolean = false;

  private readonly _update$: Subject<void>;

  public constructor() {
    super();
    this.dismiss = new EventEmitter<void>();
    this._update$ = new Subject<void>();
  }

  public ngAfterViewInit(): void {
    this._update$
      .pipe(
        throttleTime(50, asyncScheduler, { leading: true, trailing: true }),
        takeUntil(this._destroyed$)
      )
      .subscribe(() => {
        this.scrollTop = !!this.wrapper?.nativeElement.scrollTop;
        this.scrollBot = !!(
          this.wrapper?.nativeElement.scrollTop +
          getBoundingClientRect(this.wrapper?.nativeElement).height -
          this.wrapper?.nativeElement.scrollHeight
        );
      });

    setTimeout(() => {
      this._update$.next();
    });
  }

  @HostListener('window:resize')
  public onResize(): void {
    this._update$.next();
  }

  public onScroll(): void {
    this._update$.next();
  }
}
