import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  animationFrameScheduler,
  distinctUntilChanged,
  fromEvent,
  filter,
  of,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs';
import { Channel, ChatConfig } from '../../models/dto/channel';
import { AppStateService } from '../app-state.service';
import { ScriptLoaderService } from '../sys/script-loader.service';

interface NiceChatManager {
  init: (
    params: {
      serverHost: string;
      bus_no: string;
      poc: string;
      params: unknown[];
      customChatButton?: boolean;
    },
    callback?: (data: unknown) => void
  ) => void;
  setStateWindowToggle: () => void;
  setDimension: (dimensions: {
    top: number;
    left: number;
    width: number;
    height: number;
  }) => void;
}

interface NiceChatWindow extends Window {
  icPatronChat?: NiceChatManager;
}

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private _script?: HTMLScriptElement;

  private readonly _active$: BehaviorSubject<boolean>;

  public constructor(
    private readonly _appState: AppStateService,
    private readonly _scriptLoader: ScriptLoaderService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    this._active$ = new BehaviorSubject<boolean>(false);
  }

  public get active$(): Observable<boolean> {
    return this._active$.asObservable();
  }

  public bootstrap(): void {
    this._appState.channel$
      .pipe(
        distinctUntilChanged(),
        switchMap((channel: Channel | undefined) => {
          this.destroyNiceChat();

          if (channel?.chat?.enabled) {
            const src: string =
              (channel?.chat?.serverHost ?? '') +
              '/inContact/ChatClient/js/embed.min.js';
            return this._scriptLoader.load(src).pipe(
              tap(() => {
                this.bootstrapNiceChat(channel?.chat);
              })
            );
          } else {
            return of(undefined);
          }
        })
      )
      .subscribe((script?: HTMLScriptElement) => {
        this._script = script;
      });

    fromEvent(this.window, 'resize')
      .pipe(
        filter(() => this._active$.value),
        throttleTime(0, animationFrameScheduler)
      )
      .subscribe(() => {
        this.placeNiceChat();
      });
  }

  public toggle(): void {
    this.icPatronChat?.setStateWindowToggle?.();
  }

  private get window(): NiceChatWindow {
    return this._document.defaultView as NiceChatWindow;
  }

  private get icPatronChat(): NiceChatManager | undefined {
    return this.window.icPatronChat;
  }

  private bootstrapNiceChat(config?: ChatConfig): void {
    if (this.icPatronChat && config) {
      this.icPatronChat.init(
        {
          serverHost: config.serverHost ?? '',
          bus_no: config.busNo ?? '',
          poc: config.poc ?? '',
          params: [],
          customChatButton: true,
        },
        (data: unknown) => {}
      );
      this.placeNiceChat();
      this._active$.next(true);
    }
  }

  private placeNiceChat(): void {
    const padding: number = 15;
    const width: number = 380;
    const height: number = 600;
    const clientWidth: number = this._document.documentElement.clientWidth;
    const clientHeight: number = this._document.documentElement.clientHeight;

    this.icPatronChat?.setDimension({
      top: clientHeight - height - padding,
      left: clientWidth - width - padding,
      width,
      height,
    });
  }

  private destroyNiceChat(): void {
    if (this._script) {
      this._scriptLoader.remove(this._script);
      this._document.querySelector('.ie-div-position-customer-chat')?.remove();
      this.window.icPatronChat = undefined;
      this._script = undefined;
      this._active$.next(false);
    }
  }
}
