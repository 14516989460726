export const hasProp = (obj: unknown, prop: string): boolean => {
  return !!(obj && typeof obj === 'object' && prop in obj);
};

export const getProp = <T>(obj: unknown, prop: string): T | undefined => {
  return obj && typeof obj === 'object' && prop in obj
    ? (obj[prop as keyof typeof obj] as T)
    : undefined;
};

export const checkEnum = (
  value: string | undefined,
  enumerable: Record<string, string | number>
): boolean => {
  return value !== undefined && Object.values(enumerable).indexOf(value) !== -1;
};
