import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractComponent } from '../../abstract.component';
import { Variant } from 'src/app/shared/models/dto/catalog/variant';

@Component({
  selector: 'app-variants-suggestions',
  templateUrl: './variants-suggestions.component.html',
  styleUrls: ['./variants-suggestions.component.scss'],
})
export class VariantsSuggestionsComponent extends AbstractComponent {
  @Input({ required: true }) public suggestions?: Variant[];
  @Input() public disabled: boolean = false;
  @Input() public card: boolean = false;

  @Output() public add: EventEmitter<Variant>;

  public constructor() {
    super();
    this.add = new EventEmitter<Variant>();
  }
}
