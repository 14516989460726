import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppRouterService } from '../services/app-router.service';
import { AppStateService } from '../services/app-state.service';
import { SnackbarService } from '../services/snackbar.service';
import { LoggerService } from '../services/sys/logger.service';
import { environment } from 'src/environments/environment';
import { Webservices } from '../constants/routes/webservices.constant';
import { targetApi } from '../utils/url.utils';
import { Routes } from '../constants/routes/routes.constant';

@Injectable()
export class ClearBearerInterceptor implements HttpInterceptor {
  private readonly whitelist: string[] = [
    environment.apiProtocol + environment.apiHost + Webservices.token,
  ];

  /**
   * Bearer clear management
   */
  public constructor(
    private readonly _appState: AppStateService,
    private readonly _appRouter: AppRouterService,
    private readonly _logger: LoggerService,
    private readonly _snackbar: SnackbarService
  ) {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(
        (error: HttpErrorResponse): ObservableInput<HttpEvent<unknown>> => {
          if (
            error.status === 401 &&
            targetApi(request.url) &&
            !this.whitelist.includes(request.url)
          ) {
            // We clear the state (we could refresh the token)
            this._appState.logout();
            this._logger.warning(
              `Http error 401 - ${error.message}, clearing bearer and retrying`,
              request.url,
              error.error
            );

            return next
              .handle(
                request.clone({
                  setHeaders: {
                    Authorization: '',
                  },
                })
              )
              .pipe(
                catchError((error: HttpErrorResponse): Observable<never> => {
                  // If we still get an error we clear the session and redirect to the homepage
                  if (error.status === 401) {
                    this._appState.logout();
                    this._appRouter.navigate(Routes.home);
                    this._snackbar.warning('Votre session a expiré.');
                  }

                  return throwError(() => error);
                })
              );
          } else {
            return throwError(() => error);
          }
        }
      )
    );
  }
}
