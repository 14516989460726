import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { AbstractComponent } from 'src/app/shared/components/abstract.component';
import { SnackItem, SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent extends AbstractComponent implements OnInit {
  public snackItems: SnackItem[] = [];

  public constructor(private readonly _snack: SnackbarService) {
    super();
  }

  public ngOnInit(): void {
    this._snack.notification$
      .pipe(takeUntil(this._destroyed$))
      .subscribe((item: SnackItem) => {
        this.snackItems.push(item);
      });
  }

  public onClear(item: SnackItem): void {
    this.snackItems = this.snackItems.filter((i: SnackItem) => i !== item);
  }

  public trackSnack(index: number, snack: SnackItem): number {
    return snack.id;
  }
}
