import { Pipe, PipeTransform } from '@angular/core';
import { Color } from '../models/ui/color';
import { Option } from '../models/dto/catalog/option';

@Pipe({
  name: 'optionToColor',
})
export class OptionToColorPipe implements PipeTransform {
  /**
   * Convert an option to a color
   */
  public transform(option: Option): Color {
    return {
      hexa: option?.hexa ?? '#fff',
      label: option.title,
    };
  }
}
