import { CookieOptions } from '../models/sys/cookies';
import { safeDecodeURIComponent } from './safe.utils';

export const parseCookie = (cookie: string): { [key: string]: string } => {
  return cookie
    ? cookie
        .split(';')
        .map((v: string) => v.split('='))
        .reduce((acc: { [key: string]: string }, v: string[]) => {
          acc[safeDecodeURIComponent(v[0].trim())] = safeDecodeURIComponent(
            v[1].trim()
          );
          return acc;
        }, {})
    : {};
};

export const buildCookie = (
  name: string,
  value: string | undefined,
  options?: CookieOptions
): string => {
  let expires: Date | undefined;
  if (value === undefined) {
    value = '';
    expires = new Date('Thu, 01 Jan 1970 00:00:00 GMT');
  } else {
    expires = options?.expires;
  }

  let str: string = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  str += ';path=' + (options?.path ? options.path : '/');
  str += options?.domain ? ';domain=' + options.domain : '';
  str += expires ? ';expires=' + expires.toUTCString() : '';
  str += options?.sameSite ? '; SameSite=' + options.sameSite : '';
  str += options?.secure ? ';secure' : '';
  str += options?.httpOnly ? '; HttpOnly' : '';

  const cookieLength: number = str.length + 1;
  if (cookieLength > 4096) {
    console.warn(
      `Cookie '${name}' possibly not set or overflowed because it was too large (${cookieLength} > 4096 bytes)!`
    );
  }

  return str;
};
