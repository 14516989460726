import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppStateService } from '../services/app-state.service';
import { Channel } from '../models/dto/channel';

@Pipe({
  name: 'option',
})
export class OptionPipe implements PipeTransform {
  public constructor(private readonly _appState: AppStateService) {}

  /**
   * Translate an option
   */
  public transform(key: string): Observable<string> {
    return this._appState.channel$.pipe(
      map((channel: Channel | undefined) => channel?.options?.[key] ?? key)
    );
  }
}
