import { Inject, Injectable } from '@angular/core';
import { storageKey } from '../../utils/storage.utils';
import { StorageService } from './storage.service';
import { DOCUMENT } from '@angular/common';
import { buildCookie, parseCookie } from '../../utils/cookie.utils';
import { Settings } from '../../constants/settings.constant';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService implements StorageService {
  public constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  /**
   * Retrieve an item from local storage
   */
  public get(key: string): string | null {
    key = storageKey(key);
    return parseCookie(this._document.cookie)[key] ?? null;
  }

  /**
   * Sets an item in local storage
   */
  public set(key: string, value: string): void {
    key = storageKey(key);
    this._document.cookie = buildCookie(key, value, Settings.cookieOptions);
  }

  /**
   * Remove an item from local storage
   */
  public remove(key: string): void {
    key = storageKey(key);
    this._document.cookie = buildCookie(key, undefined, Settings.cookieOptions);
  }

  /**
   * Clear local storage
   */
  public clear(): void {
    Object.keys(parseCookie(this._document.cookie)).forEach((key: string) => {
      this.remove(key);
    });
  }
}
