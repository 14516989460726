import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { Privacy } from '../../models/sys/privacy';
import { TrackTag } from '../../models/sys/tracking/tags';
import { AppStateService } from '../app-state.service';
import { TrackEvents } from '../../enums/track-events.enum';
import { environment } from 'src/environments/environment';
import { axeptioSettings } from '../../utils/i18n.utils';
import { AxeptioSettings } from '../../models/sys/i18n';

interface AxeptioSDK {
  on: (event: string, callback: (...args: unknown[]) => void) => void;
  openCookies: () => void;
  setCookiesVersion: (version: string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class TagManagerService {
  private axeptioSDK?: AxeptioSDK;

  private readonly _ready$: BehaviorSubject<boolean>;
  private readonly _complete$: BehaviorSubject<boolean>;

  private readonly _privacy$: BehaviorSubject<Privacy | undefined>;

  public constructor(
    private readonly _appState: AppStateService,
    private readonly _gtm: GoogleTagManagerService,
    private readonly _ngZone: NgZone,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    this._ready$ = new BehaviorSubject<boolean>(false);
    this._complete$ = new BehaviorSubject<boolean>(false);
    this._privacy$ = new BehaviorSubject<Privacy | undefined>(undefined);
  }

  public get complete$(): Observable<boolean> {
    return this._complete$.asObservable();
  }

  public bootstrap(): void {
    this._gtm.addGtmToDom();
    this.bootstrapAxeptio();
  }

  private bootstrapAxeptio(): void {
    const window: any = this._document.defaultView;
    if (window) {
      window._axcb = window._axcb || [];
      window._axcb.push((axeptioSDK: AxeptioSDK) => {
        this.axeptioSDK = axeptioSDK;
        this.axeptioSDK.on('ready', () => {
          this._ngZone.run(() => {
            this._ready$.next(true);
          });
        });
        this.axeptioSDK.on('cookies:complete', (choices: any) => {
          // we need to reattach async events to angular scope
          this._ngZone.run(() => {
            this._privacy$.next({
              gmaps: !!choices?.gmaps,
              youtube: !!choices?.youtube,
              vimeo: !!choices?.vimeo,
              salesforce: !!choices?.salesforce,
            });
            this._complete$.next(true);
          });
        });
      });
    }

    combineLatest([this._ready$, this._privacy$]).subscribe(
      ([ready, pricacy]: [boolean, Privacy | undefined]) => {
        if (ready) {
          this._appState.privacy = pricacy ?? {
            gmaps: false,
            youtube: false,
            vimeo: false,
            salesforce: false,
          };
        }
      }
    );
  }

  public promptCookies(): void {
    // We need to make sure the proper locale is set before opening the prompt
    const settings: AxeptioSettings = axeptioSettings(this._appState.locale);
    this.axeptioSDK?.setCookiesVersion(settings.cookiesVersion);

    // Cookies prompt
    this.axeptioSDK?.openCookies();
  }

  public tag(event: TrackTag): void {
    const view: boolean = event.event === TrackEvents.pageView;
    if (
      (view && environment.tracking.views) ||
      (!view && environment.tracking.events)
    ) {
      this._gtm.pushTag(event);
    }
  }
}
