import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { Observable } from 'rxjs';
import { Webservices } from '../../constants/routes/webservices.constant';
import { Token } from '../../models/dto/token';
import { Response } from './abstract-http.service';
import { SsrHttpService } from './ssr-http.service';

@Injectable({
  providedIn: 'root',
})
export class AuthHttpService extends SsrHttpService {
  public constructor(
    @Inject(PLATFORM_ID) platformId: object,
    transferState: TransferState,
    http: HttpClient
  ) {
    super(platformId, transferState, http);
  }

  public auth(email: string, password: string): Observable<Token | null> {
    return this.post<Token>(Webservices.token, {
      data: {
        email,
        password,
      },
      response: Response.json,
    });
  }
}
