import { Injectable } from '@angular/core';
import { AppStateService } from './app-state.service';
import { ChannelHttpService } from './http/channel-http.service';
import { Channel, ChannelCode } from '../models/dto/channel';
import { channelCode, syliusLocale } from '../utils/i18n.utils';
import { Locale, SyliusLocale } from '../enums/i18n/locale.enum';
import { Webservices } from '../constants/routes/webservices.constant';
import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  public constructor(
    private readonly _appState: AppStateService,
    private readonly _channelHttp: ChannelHttpService
  ) {}

  public get checkUrl(): string {
    const code: ChannelCode = channelCode(this._appState.locale);
    const locale: SyliusLocale = syliusLocale(this._appState.locale);
    return this._channelHttp.buildUrl(
      Webservices.channel,
      { locale },
      { code },
      this._channelHttp.defaultApi
    );
  }

  public check(locale?: Locale): Observable<boolean> {
    return this._channelHttp
      .channel(
        channelCode(locale ?? this._appState.locale),
        syliusLocale(locale ?? this._appState.locale)
      )
      .pipe(
        catchError(() => of(null)),
        map((channel: Channel | null) => !!channel && !channel?.maintenance)
      );
  }
}
