import { OrderCheckoutStatusEnum } from '../enums/order/order-checkout-status.enum';
import { Address } from '../models/dto/account/address';
import { Country } from '../models/dto/account/country';
import { Order } from '../models/dto/cart/order';

export const isAddressed = (order?: Order): boolean => {
  return (
    !!order?.checkoutState &&
    [
      OrderCheckoutStatusEnum.addressed,
      OrderCheckoutStatusEnum.shipping_selected,
      OrderCheckoutStatusEnum.shippping_skipped,
      OrderCheckoutStatusEnum.payment_skipped,
      OrderCheckoutStatusEnum.payment_selected,
    ].includes(order.checkoutState)
  );
};

export const subtotal = (order?: Order): number => {
  return (order?.itemsTotal ?? 0) + (order?.taxExcludedTotal ?? 0);
};

export const total = (order?: Order, overrideShipping?: number): number => {
  if (isAddressed(order)) {
    return (
      (order?.itemsTotal ?? 0) +
      (order?.orderPromotionTotal ?? 0) +
      (overrideShipping ?? order?.shippingTotal ?? 0)
    );
  } else {
    return (order?.itemsTotal ?? 0) + (order?.orderPromotionTotal ?? 0);
  }
};

export const allowedAddresses = (
  addresses: Address[],
  countries: Country[]
): Address[] => {
  return addresses.filter((address: Address) => {
    return countries.some((country: Country) => {
      return country.code === address.countryCode;
    });
  });
};
