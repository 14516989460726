import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '../models/dto/cart/order';
import { subtotal } from '../utils/order.utils';

@Pipe({
  name: 'subtotal',
})
export class SubtotalPipe implements PipeTransform {
  public transform(order?: Order): number {
    return subtotal(order);
  }
}
