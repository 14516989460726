import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appRipple]',
})
export class RippleDirective {
  @Input() public appRippleColor: string = 'rgba(255, 255, 255, 0.8)';
  @Input() public appRippleDuration: number = 600;

  public constructor(
    private readonly _element: ElementRef,
    private readonly _renderer: Renderer2,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  @HostListener('mousedown', ['$event'])
  public handleMouseDown(e: MouseEvent): void {
    this.updateHost();

    const element: HTMLElement = this._element.nativeElement as HTMLElement;
    const bounds: DOMRect = element.getBoundingClientRect();
    const size: number =
      bounds.width > bounds.height ? bounds.width : bounds.height;

    const x: number = e.clientX - bounds.left - size / 2;
    const y: number = e.clientY - bounds.top - size / 2;

    const ripple: HTMLElement = this.createRipple(x, y, size);
    this._element.nativeElement.appendChild(ripple);

    setTimeout(() => {
      if (this._element?.nativeElement) {
        this._element.nativeElement.removeChild(ripple);
      }
    }, this.appRippleDuration);
  }

  private createRipple(x: number, y: number, size: number): HTMLElement {
    const ripple: HTMLElement = this._renderer.createElement('div');

    this._renderer.setStyle(ripple, 'position', 'absolute');
    this._renderer.setStyle(ripple, 'left', x + 'px');
    this._renderer.setStyle(ripple, 'top', y + 'px');
    this._renderer.setStyle(ripple, 'width', size + 'px');
    this._renderer.setStyle(ripple, 'height', size + 'px');
    this._renderer.setStyle(ripple, 'borderRadius', '100%');
    this._renderer.setStyle(ripple, 'backgroundColor', this.appRippleColor);
    this._renderer.setStyle(ripple, 'pointerEvents', 'none');
    this._renderer.setStyle(ripple, 'transform', 'scale(0)');
    this._renderer.setStyle(ripple, 'animationName', 'ripple');
    this._renderer.setStyle(ripple, 'animationTimingFunction', 'ease-in-out');
    this._renderer.setStyle(
      ripple,
      'animationDuration',
      this.appRippleDuration + 'ms'
    );

    return ripple;
  }

  private updateHost(): void {
    const styles: CSSStyleDeclaration | undefined =
      this._document.defaultView?.getComputedStyle(this._element.nativeElement);

    if (styles?.position === 'static') {
      this._renderer.setStyle(
        this._element.nativeElement,
        'position',
        'relative'
      );
    }

    if (styles?.overflow !== 'hidden') {
      this._renderer.setStyle(
        this._element.nativeElement,
        'overflow',
        'hidden'
      );
    }
  }
}
