<span
  class="dot"
  [ngClass]="{ border }"
  >
  @if (preview2) {
    <span
      class="bicolor"
      [title]="label | titlecase"
      >
      <span
        class="color preview1"
        [ngStyle]="{backgroundColor: preview1}"
      ></span>
      <span
        class="color preview2"
        [ngStyle]="{backgroundColor: preview2}"
      ></span>
    </span>
  } @else {
    <span
      class="unicolor"
      [title]="label | titlecase"
      [ngStyle]="{backgroundColor: preview1}"
    ></span>
  }
  <ng-template #bicolor>
    <span
      class="bicolor"
      [title]="label | titlecase"
      >
      <span
        class="color preview1"
        [ngStyle]="{backgroundColor: preview1}"
      ></span>
      <span
        class="color preview2"
        [ngStyle]="{backgroundColor: preview2}"
      ></span>
    </span>
  </ng-template>
  <ng-template #unicolor>
    <span
      class="unicolor"
      [title]="label | titlecase"
      [ngStyle]="{backgroundColor: preview1}"
    ></span>
  </ng-template>
</span>
@if (showLabel) {
  <span class="label">{{ label | titlecase }}</span>
}
