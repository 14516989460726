import { Segments } from './segments.constant';

export abstract class Routes {
  public static readonly root: string = Segments.locale;
  public static readonly home: string = [Segments.locale, Segments.home].join(
    '/'
  );
  public static readonly content: string = [
    Segments.locale,
    Segments.content,
    Segments.slug,
  ].join('/');
  public static readonly taxon: string = [
    Segments.locale,
    Segments.taxon,
    Segments.slug,
  ].join('/');
  public static readonly product: string = [
    Segments.locale,
    Segments.product,
    Segments.slug,
  ].join('/');
  public static readonly storeLocator: string = [
    Segments.locale,
    Segments.storeLocator,
    Segments.slug,
  ].join('/');
  public static readonly login: string = [Segments.locale, Segments.login].join(
    '/'
  );
  public static readonly register: string = [
    Segments.locale,
    Segments.register,
  ].join('/');
  public static readonly forgottenPassword: string = [
    Segments.locale,
    Segments.forgottenPassword,
  ].join('/');
  public static readonly account: string = [
    Segments.locale,
    Segments.account,
  ].join('/');
  public static readonly dashboard: string = [
    Segments.locale,
    Segments.account,
    Segments.dashboard,
  ].join('/');
  public static readonly informations: string = [
    Segments.locale,
    Segments.account,
    Segments.informations,
  ].join('/');
  public static readonly changePassword: string = [
    Segments.locale,
    Segments.account,
    Segments.changePassword,
  ].join('/');
  public static readonly addressBook: string = [
    Segments.locale,
    Segments.account,
    Segments.addressBook,
  ].join('/');
  public static readonly orders: string = [
    Segments.locale,
    Segments.account,
    Segments.orders,
  ].join('/');
  public static readonly orderDetail: string = [
    Segments.locale,
    Segments.account,
    Segments.orderDetails,
  ].join('/');
  public static readonly returnOrder: string = [
    Segments.locale,
    Segments.account,
    Segments.returnOrder,
  ].join('/');
  public static readonly returnSummary: string = [
    Segments.locale,
    Segments.account,
    Segments.returnSummary,
  ].join('/');
  public static readonly cart: string = [Segments.locale, Segments.cart].join(
    '/'
  );
  public static readonly checkout: string = [
    Segments.locale,
    Segments.checkout,
  ].join('/');
  public static readonly checkoutInformations: string = [
    Segments.locale,
    Segments.checkout,
    Segments.informations,
  ].join('/');
  public static readonly checkoutShipping: string = [
    Segments.locale,
    Segments.checkout,
    Segments.shipping,
  ].join('/');
  public static readonly checkoutPayment: string = [
    Segments.locale,
    Segments.checkout,
    Segments.payment,
  ].join('/');
  public static readonly checkoutComplete: string = [
    Segments.locale,
    Segments.checkout,
    Segments.complete,
  ].join('/');
  public static readonly newsletter: string = [
    Segments.locale,
    Segments.newsletter,
  ].join('/');
  public static readonly search: string = [
    Segments.locale,
    Segments.search,
  ].join('/');
  public static readonly library: string = [
    Segments.locale,
    Segments.library,
  ].join('/');
  public static readonly notFound: string = [
    Segments.locale,
    Segments.notFound,
  ].join('/');
  public static readonly news: string = [
    Segments.locale,
    Segments.news,
    Segments.slug,
  ].join('/');
  public static readonly athlete: string = [
    Segments.locale,
    Segments.athlete,
    Segments.slug,
  ].join('/');
  public static readonly error: string = [Segments.locale, Segments.error].join(
    '/'
  );
  public static readonly maintenance: string = [
    Segments.locale,
    Segments.maintenance,
  ].join('/');
}
