import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppRouterService } from '../app-router.service';
import { ScriptLoaderService } from '../sys/script-loader.service';

interface WisepopsWindow extends Window {
  wisepops?: (event?: string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class PromoService {
  public constructor(
    private readonly _appRouter: AppRouterService,
    private readonly _scriptLoader: ScriptLoaderService,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public bootstrap(): void {
    if (environment.wisepopsScript) {
      this._scriptLoader
        .load(environment.wisepopsScript)
        .subscribe((script?: HTMLScriptElement) => {
          this._appRouter.navigationEnd$.subscribe(() => {
            if (this.window.wisepops) {
              this.window.wisepops('pageview');
            }
          });
        });
    }
  }

  private get window(): WisepopsWindow {
    return this._document.defaultView as WisepopsWindow;
  }
}
