import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  public constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  public load(
    src: string,
    async: boolean = true
  ): Observable<HTMLScriptElement> {
    return new Observable<HTMLScriptElement>(
      (observer: Subscriber<HTMLScriptElement>) => {
        const script: HTMLScriptElement =
          this._document.createElement('script');

        script.setAttribute('type', 'text/javascript');
        script.setAttribute('src', src);

        if (async) {
          script.setAttribute('async', 'true');
        }

        script.onload = (): void => {
          observer.next(script);
          observer.complete();
        };

        script.onerror = (): void => {
          observer.error();
        };

        this._document.head.appendChild(script);

        return {
          unsubscribe: (): void => {},
        };
      }
    );
  }

  public remove(ref: HTMLScriptElement): void {
    this._document.head.removeChild(ref);
  }
}
