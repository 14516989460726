<span class="wrapper">
  <button
    app-icon-button
    preset="light"
    src="assets/images/icons/minus.svg"
    type="button"
    [alt]="('Moins' | translate | async) ?? ''"
    [disabled]="disabled || !this.canMinus"
    (click)="onMinus()"
  ></button>
  <span class="label">{{ value | number: '2.0-0' }}</span>
  <button
    app-icon-button
    preset="light"
    src="assets/images/icons/plus.svg"
    type="button"
    [alt]="('Plus' | translate | async) ?? ''"
    [disabled]="disabled || !this.canPlus"
    (click)="onPlus()"
  ></button>
</span>
