@if (variant) {
  <article
    [ngClass]="{ border }"
    >
    <div class="inner">
      <a
        class="link"
        [routerLink]="routes.product | route:{ slug: variant.slug }"
        [queryParams]="{ variant: variant.variant_id }"
        >
        @if (variant.images?.[0]) {
          <app-image
            class="image"
            [image]="variant.images?.[0]"
            display="fit"
          ></app-image>
        }
      </a>
      <div class="details">
        <header>
          <span class="infos">
            <span class="label">{{ variant.title }}</span>
            @if (bundle) {
              <span class="bundle">
                {{ bundle.name }} -{{ bundle.percentage }}%
              </span>
            }
          </span>
          <app-price
            class="price"
            [price]="price"
            [reverse]="true"
          ></app-price>
        </header>
        @if (variant.stock !== undefined && variant.stock <= 0) {
          <span
            class="error"
            >
            {{ 'Ce produit n\'est plus disponible.' | translate | async }}
          </span>
        }
        @if (variant.stock && variant.stock > 0 && variant.stock < (quantity ?? 0)) {
          <span
            class="error"
            >
            {{ 'Seulement {count} pièce(s) sont disponible(s).' | translate: {count: variant.stock} | async }}
          </span>
        }
        @if (quantity !== undefined) {
          <div class="controls">
            <app-number-stepper
              [disabled]="disabled || !max"
              [ngModel]="current"
              [max]="max"
              [forceValue]="true"
              (ngModelChange)="setQuantity($event)"
            ></app-number-stepper>
            <button
              app-icon-button
              src="assets/images/icons/trash.svg"
              preset="light"
              [disabled]="disabled"
              [alt]="('Supprimer' | translate | async) ?? ''"
              (click)="setQuantity(0)"
            ></button>
          </div>
        }
      </div>
    </div>
    <app-cart-options
      [variant]="variant"
      [options]="options"
      [alts]="alts"
      (variantChange)="variantChange.emit($event)"
      />
  </article>
}
