import { Injectable } from '@angular/core';

enum LogLevel {
  info = 0,
  warning = 1,
  error = 2,
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private process(level: LogLevel, ...args: unknown[]): void {
    // Create the formatted log entry
    let log: string = '[' + new Date().toISOString() + ']';

    // Simply log to the console
    switch (level) {
      case LogLevel.info:
        log += '[INFO]';
        // eslint-disable-next-line no-console
        console.info(...args);
        break;
      case LogLevel.warning:
        log += '[WARNING]';
        console.warn(...args);
        break;
      case LogLevel.error:
        log += '[ERROR]';
        console.error(...args);
        break;
    }

    // Build the log string
    args.forEach((item: unknown): void => {
      if (typeof item === 'string') {
        log += ' ' + item;
      } else if (item instanceof Error) {
        log += ' ' + item.message + ' at ' + item.stack?.split('\n')[0];
      } else {
        try {
          log += ' ' + JSON.stringify(item);
        } catch (err: unknown) {
          log += ' /!\\ Unable to stringify log param';
        }
      }
    });
  }

  public info(...args: unknown[]): void {
    this.process(LogLevel.info, ...args);
  }

  public warning(...args: unknown[]): void {
    this.process(LogLevel.warning, ...args);
  }

  public error(...args: unknown[]): void {
    this.process(LogLevel.error, ...args);
  }
}
