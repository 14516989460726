@if (suggestions?.length) {
  <div
    class="suggestions"
    [ngClass]="{ card }"
    >
    <span class="discover">{{ 'Découvrez nos recommandations' | translate | async }}</span>
    @for (variant of suggestions; track variant) {
      <app-suggestion-card
        [variant]="variant"
        [disabled]="disabled"
        [grey]="!card"
      ></app-suggestion-card>
    }
  </div>
}
