<app-loader
  class="loader"
  [ngClass]="{hidden: !loading}"
/>
<div #router class="router" [ngStyle]="{'--push-height': push ? 'inherit' : '0px'}">
  <router-outlet />
</div>
<app-snackbar class="snackbar" />
@if (chat) {
  <button
    app-button
    color="yellow"
    class="chat"
    (click)="startChat()"
  ><app-icon src="assets/images/icons/chat.svg" />{{ 'Live chat' | translate | async }}</button>
}
