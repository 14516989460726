import {
  Component,
  EventEmitter,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputComponent } from '../input.component';

export interface VariantSelectOption<T> {
  label: string;
  value: T;
  stock?: boolean;
}

@Component({
  selector: 'app-variant-select',
  templateUrl: './variant-select.component.html',
  styleUrls: ['./variant-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VariantSelectComponent),
      multi: true,
    },
  ],
})
export class VariantSelectComponent<T>
  extends InputComponent<VariantSelectOption<T>>
  implements ControlValueAccessor
{
  @Input({ required: true }) public options: VariantSelectOption<T>[] = [];
  @Input() public notification?: boolean;

  @Output() public readonly notify: EventEmitter<VariantSelectOption<T>>;

  public value?: VariantSelectOption<T>;
  public disabled: boolean = false;

  public constructor() {
    super();
    this.notify = new EventEmitter<VariantSelectOption<T>>();
  }

  public onToggle(option: VariantSelectOption<T>): void {
    if (!this.disabled) {
      this.writeValue(option);
    }
  }

  public onNotify(option: VariantSelectOption<T>): void {
    if (this.notification) {
      this.notify.emit(option);
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value?: VariantSelectOption<T>): void {
    if (this.value !== value) {
      this.value = value;
      this.onChange(value);
    }
  }
}
