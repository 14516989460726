export enum Colors {
  yellow = '#ffe200',
  black = '#181717',
  black2 = '#000',
  white = '#fff',
  grey = '#d9d9d9',
  grey2 = '#eee', // filets
  grey3 = '#f6f6f6', // fond de page
  grey4 = '#2d2929', // dark bg
  grey5 = '#bbb', // focus (à valider)
  red = '#cf2140',
}
