<span
  class="inner"
  appRipple
  [appRippleColor]="preset === 'dark' ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0.8)'"
  [ngClass]="[preset, border ? 'border' : '', large ? 'large' : '']"
>
  <app-icon [src]="src ?? ''" [alt]="alt"></app-icon>
</span>
<span class="label">
  <ng-content></ng-content>
</span>
