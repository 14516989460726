import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../enums/i18n/currency.enum';
import { Observable, map, of } from 'rxjs';
import { AppStateService } from '../services/app-state.service';
import { Locale } from '../enums/i18n/locale.enum';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  public constructor(
    private readonly _appState: AppStateService,
    private readonly _currency: CurrencyPipe
  ) {}

  public transform(
    price?: number,
    currency?: string | Currency
  ): Observable<string> {
    if (price && currency) {
      return this._appState.locale$.pipe(
        map((locale: Locale) => {
          return (
            this._currency.transform(
              price / 100,
              currency,
              undefined,
              undefined,
              locale as unknown as string
            ) ?? '--'
          );
        })
      );
    }

    return of('--');
  }
}
