import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStateService } from '../services/app-state.service';
import { targetApi } from '../utils/url.utils';

@Injectable()
export class SetBearerInterceptor implements HttpInterceptor {
  public constructor(private readonly _appState: AppStateService) {}

  /**
   * Add bearer token to the request header
   */
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      this._appState.customerToken &&
      request.method !== 'JSONP' &&
      targetApi(request.url)
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._appState.customerToken.token}`,
        },
      });
    }

    return next.handle(request);
  }
}
