import { ElementRef } from '@angular/core';

interface JSONBounds {
  height: number;
  width: number;
  left: number;
  right: number;
  top: number;
  bottom: number;
  x: number;
  y: number;
}

export const getBoundingClientRect = (
  element?: ElementRef | HTMLElement
): DOMRect => {
  const html: HTMLElement =
    element instanceof ElementRef ? element.nativeElement : element;
  if (html?.getBoundingClientRect) {
    return html.getBoundingClientRect();
  } else {
    return {
      height: 0,
      width: 0,
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      x: 0,
      y: 0,
      toJSON: (): JSONBounds => ({
        height: 0,
        width: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        x: 0,
        y: 0,
      }),
    };
  }
};
