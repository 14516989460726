import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Pages } from '../../enums/pages.enum';
import { TrackEvents } from '../../enums/track-events.enum';
import { Customer } from '../../models/dto/account/customer';
import { Order, OrderItem } from '../../models/dto/cart/order';
import { Product, ProductVariant } from '../../models/dto/catalog/product';
import { Taxon } from '../../models/dto/catalog/taxon';
import { Variant } from '../../models/dto/catalog/variant';
import { Dealer } from '../../models/dto/dealer';
import {
  DealerTag as ButtonTag,
  CartUpdateTag,
  DealerSearchTag,
  EcommerceEvents,
  EcommerceTag,
  FiltersTag,
  FooterLinkTag,
  PageViewTag,
  PreloadTag,
  ProductEvents,
  ProductSearchTag,
  ProductTag,
  SocialTag,
  TouchpointEvents,
  TouchpointTag,
  UniverseTag,
  VariantsEvents,
  VariantsTag,
} from '../../models/sys/tracking/tags';
import {
  mapCustomer,
  mapDealerFilters,
  mapOrder,
  mapOrderItem,
  mapProduct,
  mapTaxonFilters,
  mapVariants,
} from '../../utils/mappers/tracking-mappers.utils';
import { subtotal } from '../../utils/order.utils';
import { AppStateService } from '../app-state.service';
import { TagManagerService } from './tag-manager.service';

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  public constructor(
    private readonly _tagManager: TagManagerService,
    private readonly _appState: AppStateService,
    private readonly _title: Title,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {}

  public trackPage(): void {
    const window: (Window & typeof globalThis) | null =
      this._document.defaultView;
    const tag: PageViewTag = {
      event: TrackEvents.pageView,
      url:
        (window?.location?.pathname ?? '') + (window?.location?.search ?? ''),
      title: this._title.getTitle(),
    };

    this._tagManager.tag(tag);
  }

  public trackPreload(page: Pages): void {
    const tag: PreloadTag = {
      event: TrackEvents.preload,
      siteLanguage: this._appState.locale,
      pageType: page,
      ecommerce: null,
      userData: mapCustomer(this._appState.customer),
    };

    this._tagManager.tag(tag);
  }

  public trackProduct(
    event: ProductEvents,
    product?: Product,
    variant?: ProductVariant
  ): void {
    const tag: ProductTag = {
      event,
      siteLanguage: this._appState.locale,
      ecommerce: mapProduct(product, variant),
    };

    this._tagManager.tag(tag);
  }

  public trackVariants(event: VariantsEvents, variants?: Variant[]): void {
    const tag: VariantsTag = {
      event,
      siteLanguage: this._appState.locale,
      ecommerce: mapVariants(variants),
    };

    this._tagManager.tag(tag);
  }

  public trackCartUpdate(
    item: OrderItem,
    quantity: number,
    order: Order
  ): void {
    const tag: CartUpdateTag = {
      event: quantity > 0 ? TrackEvents.addToCart : TrackEvents.removeFromCart,
      siteLanguage: this._appState.locale,
      ecommerce: {
        currency: item.variant?.price?.currency,
        grandTotal: ((item.variant?.price?.price ?? 0) * quantity) / 100,
        cartValue: subtotal(order) / 100,
        products: [mapOrderItem(item, quantity)],
      },
      userData: mapCustomer(this._appState.customer),
    };

    this._tagManager.tag(tag);
  }

  public trackEcommerce(event: EcommerceEvents, order?: Order): void {
    const tag: EcommerceTag = {
      event,
      siteLanguage: this._appState.locale,
      ecommerce: mapOrder(order ?? this._appState.cart),
      userData: mapCustomer(this._appState.customer),
    };

    this._tagManager.tag(tag);
  }

  public trackFilters(filters: string[], taxon?: Taxon): void {
    const tag: FiltersTag = {
      event: TrackEvents.taxonFilters,
      categoryName: taxon?.category,
      subCategoryName: taxon?.subcategory,
      filters: taxon?.filters ? mapTaxonFilters(filters, taxon.filters) : {},
    };

    this._tagManager.tag(tag);
  }

  public trackFindRetailer(buttonID: 'header' | 'cart'): void {
    const tag: ButtonTag = {
      event: TrackEvents.findRetailer,
      buttonID,
    };

    this._tagManager.tag(tag);
  }

  public trackUniverse(name: string): void {
    const tag: UniverseTag = {
      event: TrackEvents.universe,
      universeName: name,
    };

    this._tagManager.tag(tag);
  }

  public trackProductSearch(query: string): void {
    const tag: ProductSearchTag = {
      event: TrackEvents.productSearch,
      searchTerm: query,
    };

    this._tagManager.tag(tag);
  }

  public trackDealerSearch(dealer: Dealer, tags?: string[]): void {
    const tag: DealerSearchTag = {
      event: TrackEvents.dealerSearch,
      filters: mapDealerFilters(tags),
      dealerID: dealer.id ?? 0,
      dealerName: dealer.name ?? '',
    };

    this._tagManager.tag(tag);
  }

  public trackFooterLink(link: string): void {
    const tag: FooterLinkTag = {
      event: TrackEvents.footerLinks,
      linkName: link,
    };

    this._tagManager.tag(tag);
  }

  public trackTouchpoint(event: TouchpointEvents, customer?: Customer): void {
    const tag: TouchpointTag = {
      event,
      userData: mapCustomer(customer ?? this._appState.customer),
    };

    this._tagManager.tag(tag);
  }

  public trackSocial(media: string): void {
    const tag: SocialTag = {
      event: TrackEvents.social,
      socialMedia: media,
    };

    this._tagManager.tag(tag);
  }
}
