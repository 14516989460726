@if (src) {
  <span
    role="img"
    attr.aria-labelledby="{{ alt }}"
  [ngStyle]="{
    'mask-image': 'url('+src+')',
    '-webkit-mask-image': 'url('+src+')'
  }"
  ></span>
}
