<span class="wrapper" [ngClass]="{ condensed, open, dropdown: !!options }" (appClickAway)="open = false">
  <span class="header" (click)="toggle()">
    <span class="option">
      <span class="label">{{ label }}</span>
      @if (value) {
        @if (value.hexa) {
          <app-color-dot [color]="value | optionToColor" [showLabel]="true" />
        } @else {
          <span class="value" >{{ value.title }}</span>
        }
      }
    </span>
    @if (options) {
      <app-icon class="arrow" src="assets/images/icons/circle-dropdown.svg" />
    }
  </span>

  <span class="options">
    <ul>
      @for (option of options; track option.code) {
        <li (click)="onClick(option)">
          @if (option.hexa) {
            <app-color-dot [color]="option | optionToColor" [showLabel]="true" />
          } @else {
            <span class="option">{{ option.title }}</span>
          }
        </li>
      }
    </ul>
  </span>
</span>
