import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Segments } from './shared/constants/routes/segments.constant';
import { localeBootstrapGuard } from './shared/routing/guards/locale-bootstrap.guard';
import { rootRedirectGuard } from './shared/routing/guards/root-redirect.guard';
import { stateBootstrapGuard } from './shared/routing/guards/state-bootstrap.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [rootRedirectGuard],
    pathMatch: 'full',
    children: [],
  },
  {
    path: Segments.locale,
    canActivate: [localeBootstrapGuard, stateBootstrapGuard],
    loadChildren: () =>
      import('./modules/root/root.module').then(
        (m: typeof import('./modules/root/root.module')) => m.RootModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
