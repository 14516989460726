import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Settings } from '../constants/settings.constant';

export interface SnackOptions {
  type: SnackType;
  duration: number | null;
}

export enum SnackType {
  Info,
  Success,
  Warning,
  Error,
}

export interface SnackItem extends SnackOptions {
  id: number;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly _notification$: Subject<SnackItem>;

  private static _count: number = 0;

  public constructor() {
    this._notification$ = new Subject<SnackItem>();
  }

  public get notification$(): Observable<SnackItem> {
    return this._notification$.asObservable();
  }

  private notify(item: SnackItem): void {
    this._notification$.next(item);
  }

  public info(
    message: string,
    duration: number | null = Settings.defaultSnackDuration
  ): void {
    this.open(message, { duration, type: SnackType.Info });
  }

  public success(
    message: string,
    duration: number | null = Settings.defaultSnackDuration
  ): void {
    this.open(message, { duration, type: SnackType.Success });
  }

  public warning(
    message: string,
    duration: number | null = Settings.defaultSnackDuration
  ): void {
    this.open(message, { duration, type: SnackType.Warning });
  }

  public error(
    message: string,
    duration: number | null = Settings.defaultSnackDuration
  ): void {
    this.open(message, { duration, type: SnackType.Error });
  }

  private open(message: string, options: SnackOptions): void {
    const item: SnackItem = {
      id: SnackbarService._count++,
      message,
      ...options,
    };
    this.notify(item);
  }
}
