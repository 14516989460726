import { Currency } from '../../enums/i18n/currency.enum';
import { SyliusLocale } from '../../enums/i18n/locale.enum';
import { Variant } from './catalog/variant';
import { Collection } from './collection';

export enum ChannelCode {
  default = 'default',
  de = 'AL',
  es = 'ES',
  it = 'IT',
  us = 'US',
  jp = 'JP',
  int = 'INT',
}

export enum PushCode {
  top = 'TOP',
  reassurance1 = 'REASSURANCE1',
  reassurance2 = 'REASSURANCE2',
  reassurance3 = 'REASSURANCE3',
  reassurance4 = 'REASSURANCE4',
  description = 'DESCRIPTION',
}

export interface Billing {
  company?: string;
  taxId?: number;
  street?: string;
  postCode?: string;
  city?: string;
  countryCode?: string;
}

export interface Push {
  code: PushCode;
  enabled?: boolean;
  text?: string;
}

export interface ChatConfig {
  enabled?: number;
  serverHost?: string;
  busNo?: string;
  poc?: string;
}

export interface ChannelLink {
  slug?: string;
  title?: string;
}

export interface Channel {
  baseCurrency: Currency;
  defaultLocale: SyliusLocale;
  code: ChannelCode;
  name: string;
  contactEmail?: string;
  contactPhoneNumber?: string;
  shopBillingData?: Billing;
  facebook?: string;
  twitter?: string;
  instagram?: string;
  pinterest?: string;
  youtube?: string;
  vimeo?: string;
  strava?: string;
  description?: string;
  home?: ChannelLink;
  contact?: ChannelLink;
  stores?: ChannelLink;
  cgv?: ChannelLink;
  care?: ChannelLink;
  enabled?: boolean;
  ecommerce?: boolean;
  locales?: SyliusLocale[];
  countries?: string[];
  products_cart?: Variant[];
  maintenance?: boolean;
  faqs?: { question?: string; response?: string }[];
  blocs?: Push[];
  newsletter_tags?: Collection<string>;
  store_tags?: Collection<string>;
  options?: Collection<string>;
  franco_port: number;
  adyen_alma_min: number;
  adyen_alma_max: number;
  chat?: ChatConfig;
}
