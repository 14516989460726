import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '../models/dto/cart/order';
import { total } from '../utils/order.utils';

@Pipe({
  name: 'total',
})
export class TotalPipe implements PipeTransform {
  public transform(order?: Order, overrideShipping?: number): number {
    return total(order, overrideShipping);
  }
}
