import { NgZone } from '@angular/core';
import {
  MonoTypeOperatorFunction,
  Observable,
  Subscriber,
  Subscription,
} from 'rxjs';

export const nextFrame = <T>(): MonoTypeOperatorFunction<T> => {
  return (source: Observable<T>) => {
    return new Observable((subscriber: Subscriber<T>) => {
      const subscription: Subscription = source.subscribe({
        next(value: T) {
          setTimeout(() => {
            subscriber.next(value);
          });
        },
        error(error: Error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  };
};

export const reattach = <T>(zone: NgZone): MonoTypeOperatorFunction<T> => {
  return (source: Observable<T>) => {
    return new Observable((subscriber: Subscriber<T>) => {
      const subscription: Subscription = source.subscribe({
        next(value: T) {
          zone.run(() => {
            subscriber.next(value);
          });
        },
        error(error: Error) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  };
};
