import { Settings } from '../constants/settings.constant';
import { FamilyCodes } from '../enums/api/family-codes.enum';
import { Locale } from '../enums/i18n/locale.enum';
import { Product, ProductVariant } from '../models/dto/catalog/product';
import { Variant } from '../models/dto/catalog/variant';
import { Channel } from '../models/dto/channel';
import { I18N } from '../models/sys/i18n';
import { i18nByAppLocale } from './i18n.utils';

export const hasDesc = (product?: Product): boolean => {
  return !!product?.description;
};

export const hasCaracs = (product?: Product): boolean => {
  return !!product?.benefits?.length;
};

export const hasSpecs = (product?: Product): boolean => {
  return !!product?.features?.SPECS?.length;
};

export const hasTechs = (product?: Product): boolean => {
  return !!product?.features?.TECHNOS?.length;
};

export const hasBundle = (product?: Product): boolean => {
  return !!product?.bundle;
};

export const hasRecos = (variant?: ProductVariant): boolean => {
  return !!variant?.cross_selling?.length;
};

export const hasAdvices = (product?: Product): boolean => {
  return !!product?.news?.length;
};

export const hasDocs = (product?: Product): boolean => {
  return !!product?.documents?.length;
};

export const hasDetails = (
  product?: Product,
  variant?: ProductVariant
): boolean => {
  return (
    hasDesc(product) ||
    hasCaracs(product) ||
    hasSpecs(product) ||
    hasTechs(product) ||
    hasBundle(product) ||
    hasRecos(variant) ||
    hasAdvices(product) ||
    hasDocs(product)
  );
};

export const filterSuggestions = (
  suggestions?: Variant[],
  exclude?: (Variant | ProductVariant)[]
): Variant[] | undefined => {
  return (
    suggestions?.filter(
      (v: Variant) =>
        v && !exclude?.find((e: Variant | ProductVariant) => v.code === e.code)
    ) ?? undefined
  );
};

export const isSelling = (
  family: FamilyCodes | undefined,
  channel: Channel | undefined,
  locale: Locale
): boolean => {
  return !!(
    channel?.ecommerce &&
    (!family || !Settings.excludeSales.includes(family)) &&
    isDelivered(locale, channel)
  );
};

export const isDelivered = (locale: Locale, channel?: Channel): boolean => {
  const i18n: I18N | undefined = i18nByAppLocale(locale);
  return !!channel?.countries?.find(
    (country: string) =>
      country.toLocaleLowerCase() === i18n?.countryCode?.toLocaleLowerCase()
  );
};
