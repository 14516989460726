@if (price?.price) {
  <div
    class="wrapper"
    [ngClass]="{ promo: price?.original_price && price?.original_price !== price?.price , condensed, reverse }"
    >
    <span class="value">
      @if (price?.original_price && price?.original_price !== price?.price) {
        <span
          class="original"
        >{{ price?.original_price | price:price?.currency | async }}</span>
      }
      <span class="current">{{ price?.price | price:price?.currency | async }}</span>
    </span>
    @if (price?.original_price && price?.original_price !== price?.price) {
      <span
        class="reduction"
      >{{ price?.price | promo:price?.original_price}}</span>
    }
  </div>
}
