import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
} from '@angular/platform-browser';

@Pipe({
  name: 'trust',
})
export class TrustPipe implements PipeTransform {
  public constructor(private readonly _sanitizer: DomSanitizer) {}

  public transform(
    value?: string,
    type?: 'html' | 'style' | 'script' | 'url' | 'resource'
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value ?? '');
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value ?? '');
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value ?? '');
      case 'resource':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value ?? '');
      case 'html':
      default:
        return this._sanitizer.bypassSecurityTrustHtml(value ?? '');
    }
  }
}
