import { Inject, Injectable, Optional } from '@angular/core';
import {
  Event,
  NavigationEnd,
  NavigationStart,
  Router,
  Scroll,
} from '@angular/router';
import { RESPONSE } from '../../../express.tokens';
import { Response } from 'express';
import { Observable, Subject, filter } from 'rxjs';
import { Metadata } from '../models/sys/metadata';
import { RequestParams } from '../models/sys/routing';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root',
})
export class AppRouterService {
  private _resolving: boolean = false;
  private readonly _metadata$: Subject<Metadata | undefined>;

  private _skipNextScrollEvent: boolean = false;

  public constructor(
    private readonly _appState: AppStateService,
    private readonly _router: Router,
    @Optional() @Inject(RESPONSE) private readonly _response: Response
  ) {
    this.navigationStart$.subscribe(() => {
      this._resolving = true;
    });

    this.navigationEnd$.subscribe(() => {
      this._resolving = false;
    });

    this._metadata$ = new Subject<Metadata | undefined>();
  }

  public get url(): string {
    return this._router.routerState.snapshot.url;
  }

  public get route(): string {
    return this.url.split('?')[0];
  }

  public get resolving(): boolean {
    return this._resolving;
  }

  /**
   * Url changed, will trigger guards/resolvers
   */
  public get navigationStart$(): Observable<NavigationStart> {
    return this._router.events.pipe(
      filter((event: Event) => event instanceof NavigationStart)
    );
  }

  /**
   * Navigation ended, guards/resolvers resolved
   */
  public get navigationEnd$(): Observable<NavigationEnd> {
    return this._router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd)
    );
  }

  /**
   * Navigation scroll events
   */
  public get navigationScroll$(): Observable<Scroll> {
    return this._router.events.pipe(
      filter((event: Event) => event instanceof Scroll),
      filter((event: Event) => {
        if (this._skipNextScrollEvent) {
          this._skipNextScrollEvent = false;
          return false;
        } else {
          return true;
        }
      })
    ) as Observable<Scroll>;
  }

  public set metadata(value: Metadata | undefined) {
    this._metadata$.next(value);
  }

  /**
   * Navigation complete, view initialized
   */
  public get metadata$(): Observable<Metadata | undefined> {
    return this._metadata$.asObservable();
  }

  public skipNextScrollEvent(): void {
    this._skipNextScrollEvent = true;
  }

  /**
   * Proper navigation with params and attributes
   */
  public navigate(
    url: string,
    params?: RequestParams,
    attrs?: RequestParams
  ): Promise<boolean> {
    if (params) {
      Object.keys(params).forEach((key: string) => {
        if (params[key] instanceof Date) {
          // Date iso formatting
          params[key] = params[key].toISOString();
        } else if (
          Array.isArray(params[key]) &&
          (params[key] as unknown[]).length === 0
        ) {
          // Arrays cleanup
          params[key] = undefined;
        }
      });
    }

    const route: string = this.prepareRoute(url, attrs);

    if (this._appState.ssr) {
      this.ssrRedirect(route, params);
      return Promise.resolve(true);
    } else {
      return this._router.navigate(
        [route],
        params ? { queryParams: params } : undefined
      );
    }
  }

  /**
   * Navigation change with params update only
   */
  public setParams(params?: RequestParams): Promise<boolean> {
    return this.navigate(this.route, params);
  }

  /**
   * Params mapping
   */
  public prepareRoute(route: string, attrs?: RequestParams): string {
    attrs = attrs ?? {};
    if (!attrs['locale']) {
      attrs = {
        ...attrs,
        locale: this._appState.locale,
      };
    }

    for (const key in attrs) {
      if (attrs[key] !== undefined && attrs[key] !== null) {
        route = route.replace(':' + key, this.normalize(attrs[key]));
      }
    }

    return (!route.startsWith('/') ? '/' : '') + route;
  }

  /**
   * Array param mapping
   */
  public getParamsToArray(param: unknown, number: boolean = false): unknown[] {
    if (param !== undefined) {
      if (Array.isArray(param)) {
        return param.map((value: string) =>
          number ? parseInt(value, 10) : value
        );
      } else {
        return [number ? parseInt(param as string, 10) : param];
      }
    } else {
      return [];
    }
  }

  /**
   * Url attribute normalization (gets rid of accents, special chars, etc.)
   */
  private normalize(value: unknown): string {
    return (<string>value + '').trim().normalize('NFD');
    // .replace(/[\s+_':]/g, '-')
    // .replace(/[^a-zA-Z0-9\-/]/g, '')
    // .toLocaleLowerCase();
  }

  /**
   * Server side redirection
   */
  private ssrRedirect(route: string, params?: RequestParams): void {
    if (this._appState.ssr) {
      const redirect: string =
        route +
        (params
          ? '?' +
            Object.keys(params)
              .map(
                (key: string) =>
                  key +
                  '=' +
                  (typeof params[key] === 'string'
                    ? params[key]
                    : JSON.stringify(params[key]))
              )
              .join('&')
          : '');

      if (redirect !== this.url) {
        this._response.location(redirect);
        this._response.status(302);
      }
    }
  }
}
