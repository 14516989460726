import { Injectable } from '@angular/core';
import { StorageKeys } from '../enums/storage-keys.enum';
import { ChannelCode } from '../models/dto/channel';
import { Token } from '../models/dto/token';
import { LocalStorageService } from './storage/local-storage.service';
import { SessionStorageService } from './storage/session-storage.service';

interface ChannelData<T> {
  channel: ChannelCode;
  data: T;
}

@Injectable({
  providedIn: 'root',
})
export class ChannelTokensService {
  private readonly _cartTokens: ChannelData<string>[] = [];
  private readonly _customerTokens: ChannelData<Token>[] = [];
  private readonly _orderCompleteTokens: ChannelData<string>[] = [];

  public constructor(
    private readonly _localStorage: LocalStorageService,
    private readonly _sessionStorage: SessionStorageService
  ) {
    this._customerTokens = this.loadTokens(StorageKeys.customerTokens);
    this._cartTokens = this.loadTokens(StorageKeys.cartTokens);
    this._orderCompleteTokens = this.loadTokens(
      StorageKeys.orderCompleteTokens,
      true
    );
  }

  private loadTokens<T>(key: StorageKeys, session?: boolean): ChannelData<T>[] {
    let tokens: ChannelData<T>[] = [];
    try {
      const jsonData: unknown = JSON.parse(
        (session
          ? this._sessionStorage.get(key)
          : this._localStorage.get(key)) ?? 'null'
      );
      tokens = Array.isArray(jsonData) ? jsonData : [];
    } catch (e) {
      tokens = [];
    }

    return tokens;
  }

  private getToken<T>(
    tokens: ChannelData<T>[],
    channel: ChannelCode
  ): ChannelData<T> | undefined {
    return tokens.find((t: ChannelData<T>) => t.channel === channel);
  }

  private setToken<T>(
    value: T | undefined,
    channel: ChannelCode,
    tokens: ChannelData<T>[],
    key: StorageKeys,
    session?: boolean
  ): void {
    const index: number = tokens.findIndex(
      (t: ChannelData<T>) => t.channel === channel
    );

    if (value) {
      const data: ChannelData<T> = { data: value, channel };
      if (index !== -1) {
        tokens[index] = data;
      } else {
        tokens.push(data);
      }
    } else if (index !== -1) {
      tokens.splice(index, 1);
    }

    if (session) {
      this._sessionStorage.set(key, JSON.stringify(tokens));
    } else {
      this._localStorage.set(key, JSON.stringify(tokens));
    }
  }

  public getCustomerToken(channel: ChannelCode): Token | undefined {
    return this.getToken(this._customerTokens, channel)?.data;
  }

  public setCustomerToken(
    value: Token | undefined,
    channel: ChannelCode
  ): void {
    this.setToken(
      value,
      channel,
      this._customerTokens,
      StorageKeys.customerTokens
    );
  }

  public getCartToken(channel: ChannelCode): string | undefined {
    return this.getToken(this._cartTokens, channel)?.data;
  }

  public setCartToken(value: string | undefined, channel: ChannelCode): void {
    this.setToken(value, channel, this._cartTokens, StorageKeys.cartTokens);
  }

  public getOrderCompleteToken(channel: ChannelCode): string | undefined {
    return this.getToken(this._orderCompleteTokens, channel)?.data;
  }

  public setOrderCompleteToken(
    value: string | undefined,
    channel: ChannelCode
  ): void {
    this.setToken(
      value,
      channel,
      this._orderCompleteTokens,
      StorageKeys.orderCompleteTokens,
      true
    );
  }
}
