<ul class="options" [ngClass]="{ condensed }">
  @for (option of variant?.options | keyvalue; track option) {
    <li class="option">
      <app-cart-select
        [label]="(option.key | option | async) ?? ''"
        [value]="option.value"
        [options]="activeOptions?.[option.key]"
        [condensed]="condensed"
        (optionChange)="onOptionChange($event)"
        />
    </li>
  }
</ul>
