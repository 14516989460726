import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VariantSelectOption } from '../variant-select.component';
import { AbstractComponent } from '../../../abstract.component';

@Component({
  selector: 'app-variant-option',
  templateUrl: './variant-option.component.html',
  styleUrls: ['./variant-option.component.scss'],
})
export class VariantOptionComponent<T> extends AbstractComponent {
  @Input({ required: true }) public option!: VariantSelectOption<T>;
  @Input() public selected?: boolean;
  @Input() public disabled?: boolean;
  @Input() public notification?: boolean;

  @Output() public readonly toggle: EventEmitter<VariantSelectOption<T>>;

  public constructor() {
    super();
    this.toggle = new EventEmitter<VariantSelectOption<T>>();
  }

  public onClick(): void {
    this.toggle.emit(this.option);
  }
}
