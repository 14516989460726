import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractComponent } from '../../abstract.component';
import { Color } from 'src/app/shared/models/ui/color';

@Component({
  selector: 'app-color-dot',
  templateUrl: './color-dot.component.html',
  styleUrls: ['./color-dot.component.scss'],
})
export class ColorDotComponent extends AbstractComponent implements OnChanges {
  @Input({ required: true }) public color!: Color;
  @Input() public showLabel: boolean = false;

  public preview1?: string;
  public preview2?: string;

  public border: boolean = false;

  public label?: string;

  public ngOnChanges(changes: SimpleChanges): void {
    [this.preview1, this.preview2] = this.split(this.color.hexa);

    if (this.color.label) {
      const [label1, label2]: [string, string | undefined] = this.split(
        this.color.label
      );

      this.label = label1;
      if (label2) {
        this.label += ' / ' + label2;
      }
    } else {
      this.label = undefined;
    }

    this.border = !!['#fff', '#ffffff'].find(
      (color: string) =>
        color.toLocaleLowerCase() === this.preview1?.toLocaleLowerCase()
    );
  }

  public split(str: string): [string, string | undefined] {
    const slashes: string[] = str.split('/');
    const quotes: string[] = str.split('-');

    if (slashes.length === 2) {
      return slashes as [string, string | undefined];
    } else if (quotes.length === 2) {
      return quotes as [string, string | undefined];
    } else {
      return [str, undefined];
    }
  }
}
