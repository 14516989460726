import { Customer } from '../../models/dto/account/customer';
import { Order, OrderItem } from '../../models/dto/cart/order';
import { Price } from '../../models/dto/catalog/price';
import { Product, ProductVariant } from '../../models/dto/catalog/product';
import { TaxonFilter } from '../../models/dto/catalog/taxon';
import { Variant } from '../../models/dto/catalog/variant';
import {
  TrackEcommerce,
  TrackFilters,
  TrackProduct,
  TrackUser,
} from '../../models/sys/tracking/track-data';
import { subtotal } from '../order.utils';

export const mapCustomer = (customer?: Customer): TrackUser | undefined => {
  return customer
    ? {
        userID: customer.id,
        userFirstName: customer.firstName,
        userLastName: customer.lastName,
        userMail: customer.email,
        userPhone: customer.phoneNumber ?? undefined,
      }
    : undefined;
};

export const mapProduct = (
  product?: Product,
  variant?: ProductVariant
): TrackEcommerce => {
  return variant
    ? {
        currency: variant.price?.currency,
        grandTotal: mapPrice(variant.price),
        products: variant
          ? [
              {
                productSKU: variant.code,
                productName: variant.name,
                quantity: 1,
                price: mapBasePrice(variant.price),
                discount: mapDiscount(variant.price),
                categoryName: product?.category,
                subCategoryName: product?.subcategory,
              },
            ]
          : [],
      }
    : { products: [] };
};

export const mapPrice = (price?: Price): number => {
  return (price?.price ?? 0) / 100;
};

export const mapBasePrice = (price?: Price): number => {
  return (price?.original_price ?? price?.price ?? 0) / 100;
};

export const mapDiscount = (price?: Price): number => {
  return (
    (price?.original_price && price?.original_price !== price?.price
      ? price?.original_price - (price?.price ?? 0)
      : 0) / 100
  );
};

export const mapVariants = (variants?: Variant[]): TrackEcommerce => {
  const products: TrackProduct[] =
    variants?.map((variant: Variant) => mapVariant(variant)) ?? [];

  return variants?.length
    ? {
        currency: variants?.length ? variants[0].price?.currency : undefined,
        grandTotal: products.reduce(
          (acc: number, product: TrackProduct) =>
            acc +
            ((product.price ?? 0) - (product.discount ?? 0)) *
              (product.quantity ?? 1),
          0
        ),
        products,
      }
    : { products: [] };
};

export const mapVariant = (variant: Variant): TrackProduct => {
  return {
    productSKU: variant.code,
    productName: variant.title,
    quantity: 1,
    price: mapBasePrice(variant.price),
    discount: mapDiscount(variant.price),
    categoryName: variant.category,
    subCategoryName: variant.subcategory,
  };
};

export const mapOrder = (order?: Order): TrackEcommerce => {
  const shipping: string | undefined = order?.shipments?.length
    ? order.shipments[0].method?.split('/')?.slice(-1)?.[0]
    : undefined;
  const payment: string | undefined = order?.payments?.length
    ? order.payments[0].method?.split('/')?.slice(-1)?.[0]
    : undefined;

  return order
    ? {
        currency: order.currency,
        grandTotal: subtotal(order) / 100,
        shippingTier: shipping,
        coupon: order.couponCode,
        paymentType: payment,
        orderID: order.id,
        tax: (order.taxTotal ?? 0) / 100,
        newCustomer: order.newCustomer,
        orderCount: order.orderCount,
        products:
          order.items?.map((item: OrderItem) => mapOrderItem(item)) ?? [],
      }
    : {
        products: [],
      };
};

export const mapOrderItem = (
  item: OrderItem,
  quantity?: number
): TrackProduct => {
  const price: number = item.originalUnitPrice ?? item.unitPrice ?? 0;
  const discount: number = price - (item.unitPrice ?? 0);

  return {
    productSKU: item.variant?.code,
    productName: item.variant?.title,
    quantity: quantity ?? item.quantity,
    price: price / 100,
    discount: discount / 100,
    categoryName: item.variant?.category,
    subCategoryName: item.variant?.subcategory,
  };
};

export const mapTaxonFilters = (
  selection: string[],
  filters: TaxonFilter
): TrackFilters => {
  const result: TrackFilters = {};
  Object.keys(filters).forEach((key: string) => {
    const filter: string[] = Object.keys(filters[key])
      .filter((k: string) => selection.includes(k))
      .map((k: string) => filters[key][k]);

    if (filter.length) {
      result[key] = filter;
    }
  });

  return result;
};

export const mapDealerFilters = (tags?: string[]): TrackFilters | undefined => {
  return tags
    ? {
        tag: tags,
      }
    : undefined;
};
