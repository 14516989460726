import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { AppStateService } from '../services/app-state.service';

@Directive({
  selector: '[appSlideToggle]',
})
export class SlideToggleDirective implements AfterViewInit, OnChanges {
  @Input() public appSlideToggle: boolean = false;
  @Input() public appSlideToggleTiming: number = 400;

  private _initialized: boolean = false;

  private _timeout?: NodeJS.Timeout;

  public constructor(
    private readonly _appState: AppStateService,
    private readonly _element: ElementRef,
    private readonly _renderer: Renderer2
  ) {}

  private get element(): HTMLElement {
    return this._element.nativeElement as HTMLElement;
  }

  private get scrollHeight(): number {
    return this.element.scrollHeight ?? 0;
  }

  public ngAfterViewInit(): void {
    this._renderer.setStyle(this.element, 'overflow', 'hidden');
    this._initialized = true;
    this.update(false);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this._initialized) {
      this.update(true);
    }
  }

  private enableTransition(enabled: boolean): void {
    if (enabled) {
      this._renderer.setStyle(
        this.element,
        'transition',
        `max-height ${this.appSlideToggleTiming}ms ease-out`
      );
    } else {
      this._renderer.removeStyle(this.element, 'transition');
    }
  }

  private update(animate: boolean): void {
    if (this.appSlideToggle) {
      this.show(animate);
    } else {
      this.hide(animate);
    }
  }

  private hide(animate: boolean): void {
    if (this._appState.ssr) {
      this._renderer.setStyle(this.element, 'maxHeight', 0);
      return;
    }

    clearTimeout(this._timeout);
    this.enableTransition(animate);
    if (animate) {
      this._renderer.setStyle(
        this.element,
        'maxHeight',
        this.scrollHeight + 'px'
      );
      this._timeout = setTimeout(() => {
        this._renderer.setStyle(this.element, 'maxHeight', 0);
      });
    } else {
      this._renderer.setStyle(this.element, 'maxHeight', 0);
    }
  }

  private show(animate: boolean): void {
    if (this._appState.ssr) {
      this._renderer.setStyle(this.element, 'maxHeight', 'auto');
      return;
    }

    clearTimeout(this._timeout);
    this.enableTransition(animate);
    this._renderer.setStyle(
      this.element,
      'maxHeight',
      this.scrollHeight + 'px'
    );
    this._timeout = setTimeout(() => {
      this._renderer.removeStyle(this.element, 'maxHeight');
    }, this.appSlideToggleTiming + 50);
  }
}
