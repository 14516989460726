import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, TransferState } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { Webservices } from '../../constants/routes/webservices.constant';
import { Customer } from '../../models/dto/account/customer';
import { Response } from './abstract-http.service';
import { SsrHttpService } from './ssr-http.service';
import { SyliusLocale } from '../../enums/i18n/locale.enum';
import { Token } from '../../models/dto/token';

@Injectable({
  providedIn: 'root',
})
export class CustomerHttpService extends SsrHttpService {
  public constructor(
    @Inject(PLATFORM_ID) platformId: object,
    transferState: TransferState,
    http: HttpClient
  ) {
    super(platformId, transferState, http);
  }

  public find(id: number): Observable<Customer | null> {
    return this.get<Customer>(`${Webservices.customers}/${id}`, {
      response: Response.json,
    });
  }

  public findByToken(token: Token): Observable<Customer | null> {
    return this.get<Customer>(token.customer, {
      response: Response.json,
    });
  }

  public create(customer: Customer): Observable<void | null> {
    return this.post<void>(`${Webservices.customers}`, {
      data: customer,
      response: Response.json,
    });
  }

  public update(customer: Customer): Observable<Customer | null> {
    return this.put<Customer>(`${Webservices.customers}/${customer.id}`, {
      data: customer,
      response: Response.json,
    });
  }

  public changePassword(
    id: number,
    currentPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ): Observable<void | null> {
    return this.put<void>(`${Webservices.password}`, {
      attrs: { id },
      data: {
        currentPassword,
        newPassword,
        confirmNewPassword,
      },
      response: Response.json,
    });
  }

  public requestVerifyEmail(): Observable<unknown> {
    return this.post(`${Webservices.verify}`, {
      data: {},
      response: Response.json,
    });
  }

  public verifyAccount(token: string): Observable<unknown> {
    return this.patch(`${Webservices.verify}/${token}`, {
      data: { token },
      response: Response.json,
    });
  }

  public forgotPassword(
    email: string,
    language: SyliusLocale
  ): Observable<unknown> {
    return this.post(`${Webservices.forgottenPassword}`, {
      data: {
        email,
        locale: language,
      },
      response: Response.json,
    });
  }

  public resetPassword(password: string, token: string): Observable<unknown> {
    return this.patch(`${Webservices.forgottenPassword}/${token}`, {
      data: {
        newPassword: password,
        confirmNewPassword: password,
      },
      response: Response.json,
    });
  }

  public checkEmail(email: string): Observable<boolean> {
    return this.post<boolean>(Webservices.checkEmail, {
      data: { email },
      response: Response.json,
    }).pipe(
      map(() => false),
      catchError((error: HttpErrorResponse) => {
        return of(error?.status === 400);
      })
    );
  }
}
