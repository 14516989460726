import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable()
export class CatchErrorService implements ErrorHandler {
  public constructor(private _logger: LoggerService) {}

  public handleError(error: unknown): void {
    this._logger.error(error);
  }
}
