import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { AppStateService } from '../../services/app-state.service';
import { UserService } from '../../services/user.service';
import { Observable, catchError, lastValueFrom, of } from 'rxjs';

export const stateBootstrapGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): Promise<boolean> => {
  // Bootstrap state related stuff, e.g. : load user from token
  const appState: AppStateService = inject(AppStateService);
  const user: UserService = inject(UserService);
  const bootstrap$: Observable<void> = user
    .bootstrapCustomer()
    .pipe(catchError(() => of(undefined)));

  return lastValueFrom(bootstrap$).then(() => {
    appState.setReady();
    return true;
  });
};
