<app-panel-layout
  [title]="('Votre article a bien été ajouté' | translate | async) ?? ''"
  (dismiss)="close()"
  >
  <div
    class="content"
    content
    >
    <div class="summary">
      <div class="contact">
        <span class="questions">{{ 'Des questions ?' | translate | async }}</span>
        <a
          [routerLink]="routes.content | route:{ slug: contact?.slug }"
          (click)="close()"
        >{{ 'Contactez notre assistance' | translate | async }}</a>
      </div>
      <div class="price">
        <span class="total">{{ 'Total' | translate | async }} {{ cart | total:0 | price: cart?.currencyCode | async }}</span>
        <span>{{ 'Retours sans frais' | translate | async }}</span>
      </div>
    </div>
    @for (item of data.items; track item) {
      @if (item) {
        <app-cart-item-card
          [variant]="item.variant"
          [price]="item | orderItemPrice:cart?.currencyCode"
          [quantity]="item.quantity"
          [bundle]="item | orderItemBundle:cart?.bundles"
          [max]="(item.variant?.stock ?? 0) > 0 ? item.variant?.stock : undefined"
          [border]="true"
          [disabled]="updating"
          (quantityChange)="onQuantityChange(item, $event)"
          />
      }
    }
    @if (data.suggestions?.length) {
      <app-variants-suggestions
        [suggestions]="data.suggestions"
        [card]="true"
        [disabled]="updating"
        (add)="onAddSuggestion($event)"
        />
    }
  </div>
  <div
    class="footer"
    footer
    >
    <div class="x4">
      <span>{{ 'Total' | translate | async }}</span>
      @if (x4) {
        <span
          class="from"
        [innerHTML]="('<u>à partir de {x4}/mois</u> ou {total}' | translate: {
          x4: ((cart | subtotal) / 4 | price: cart?.currencyCode | async) ?? '',
          total: (cart | subtotal | price: cart?.currencyCode | async) ?? ''
        } | async) ?? '' | trust:'html'"
        ></span>
      }
      @if (!x4) {
        <span
          class="from"
        >{{ cart?.itemsTotal | price: cart?.currencyCode | async }}</span>
      }
    </div>
    <a
      app-button
      [routerLink]="routes.cart | route"
      (click)="close()"
    >{{ 'Voir mon panier' | translate | async }}</a>
  </div>
</app-panel-layout>
