import { environment } from 'src/environments/environment';
import { Currency } from '../../enums/i18n/currency.enum';
import { SchemaTypes } from '../../enums/schema-types.enum';
import { Breadcrumb } from '../../models/dto/breadcrumb';
import {
  Product as ProductDto,
  ProductVariant,
} from '../../models/dto/catalog/product';
import { Taxon } from '../../models/dto/catalog/taxon';
import { Variant } from '../../models/dto/catalog/variant';
import { Channel, Push, PushCode } from '../../models/dto/channel';
import { Pagination } from '../../models/dto/pagination';
import {
  BreadcrumbList,
  ItemList,
  Organization,
  Product,
} from '../../models/sys/schema-org';
import { Network } from '../../models/ui/network';
import { networksMapper } from './ui-mappers.utils';

export const organizationMapper = (
  route: string,
  channel?: Channel
): Organization => {
  return {
    '@type': SchemaTypes.organization,
    name: 'Mavic',
    url: environment.baseUrl + route,
    logo: environment.baseUrl + '/assets/images/logo.svg',
    description:
      channel?.blocs?.find((value: Push) => value.code === PushCode.description)
        ?.text ?? '',
    sameAs: channel
      ? networksMapper(channel).map((network: Network) => network.url)
      : [],
  };
};

export const breadcrumbsMapper = (
  breadcrumbs?: Breadcrumb[]
): BreadcrumbList => {
  return {
    '@type': SchemaTypes.breadcrumbList,
    itemListElement:
      breadcrumbs?.map((breadcrumb: Breadcrumb, index: number) =>
        breadcrumb.link
          ? {
              '@type': SchemaTypes.listItem,
              name: breadcrumb.title ?? '',
              position: (index + 1).toString(10),
              item: environment.baseUrl + breadcrumb.link,
            }
          : {
              '@type': SchemaTypes.listItem,
              name: breadcrumb.title ?? '',
              position: (index + 1).toString(10),
            }
      ) ?? [],
  };
};

export const taxonMapper = (
  prepareRoute: (slug?: string) => string,
  taxon?: Taxon,
  variants?: Pagination<Variant>
): ItemList => {
  return {
    '@type': SchemaTypes.itemList,
    name: taxon?.h1 ?? '',
    numberOfItems: variants?.meta?.totalItems?.toString(10) ?? '0',
    itemListElement:
      variants?.data.map((variant: Variant, index: number) => ({
        '@type': SchemaTypes.listItem,
        name: variant.title ?? '',
        position: (index + 1).toString(10),
        item: prepareRoute(variant?.slug),
      })) ?? [],
  };
};

export const productMapper = (
  product?: ProductDto,
  variant?: ProductVariant
): Product => {
  return {
    '@type': SchemaTypes.product,
    brand: 'Mavic',
    name: variant?.name ?? '',
    description: product?.shortDescription ?? '',
    image: variant?.images?.[0]?.cachedPath ?? '',
    sku: variant?.code ?? '',
    aggregateRating: {
      '@type': SchemaTypes.aggregateRating,
      ratingValue: '4.8',
      ratingCount: '293',
    },
    offers: {
      '@type': SchemaTypes.offer,
      price: variant?.price?.price
        ? (variant.price.price / 10).toString(10)
        : '0',
      priceCurrency: variant?.price?.currency ?? Currency.eur,
      availability: variant?.stock
        ? 'https://schema.org/InStock'
        : 'https://schema.org/OutOfStock',
    },
  };
};
