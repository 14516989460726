import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { AbstractComponent } from '../abstract.component';

@Component({ template: '' })
export abstract class DialogComponent<R> extends AbstractComponent {
  public constructor(private readonly _dialogRef: DialogRef<R>) {
    super();
  }

  public close(result?: R): void {
    this._dialogRef.close(result);
  }
}
