import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppStateService } from '../services/app-state.service';
import { channelCode } from '../utils/i18n.utils';
import { targetApi } from '../utils/url.utils';

@Injectable()
export class SetChannelInterceptor implements HttpInterceptor {
  public constructor(private readonly _appState: AppStateService) {}

  /**
   * Add language to the request header
   */
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.method !== 'JSONP' &&
      !request.params.get('channelCode') &&
      targetApi(request.url)
    ) {
      request = request.clone({
        params: request.params.set(
          'channelCode',
          channelCode(this._appState.locale)
        ),
      });
    }

    return next.handle(request);
  }
}
