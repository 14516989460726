import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  distinct,
  distinctUntilChanged,
  from,
  map,
  of,
  skip,
  switchMap,
  tap,
} from 'rxjs';
import { Settings } from '../../constants/settings.constant';
import { Locale } from '../../enums/i18n/locale.enum';
import { AppStateService } from '../app-state.service';
import { HttpTranslationLoaderService } from './loaders/http-translation-loader.service';
import MessageFormat, { MessageFunction } from '@messageformat/core';
import { LoggerService } from '../sys/logger.service';

interface FlattenedTranslations {
  [key: string]: string;
}

interface Translator {
  locale: Locale;
  formatter: MessageFormat;
  translations: FlattenedTranslations;
}

export interface Translations {
  [key: string]: Translations | string;
}

export interface TranslationValues {
  [key: string]: string | number | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  private readonly _instant: Map<Locale, Translator>;
  private readonly _translators: Map<Locale, Promise<Translator>>;

  public constructor(
    private readonly _appState: AppStateService,
    private readonly _loader: HttpTranslationLoaderService,
    private readonly _logger: LoggerService
  ) {
    this._instant = new Map<Locale, Translator>();
    this._translators = new Map<Locale, Promise<Translator>>();
  }

  /**
   * Bootstrap (load the current locale and the default one)
   */
  public bootstrap(): Promise<void> {
    this._appState.locale$
      .pipe(skip(1), distinct())
      .subscribe((lang: Locale) => {
        this.load(lang);
      });

    const locales: Locale[] =
      this._appState.locale !== Settings.defaultLocale
        ? [this._appState.locale, Settings.defaultLocale]
        : [this._appState.locale];

    return Promise.all(locales.map((locale: Locale) => this.load(locale))).then(
      () => undefined as void
    );
  }

  /**
   * Translation loading (can be overrided by injecting another TranslationLoaderService service)
   */
  private load(locale: Locale): Promise<Translator> {
    if (!this._translators.has(locale)) {
      this._translators.set(
        locale,
        new Promise<Translator>((resolve: (value: Translator) => void) => {
          this._loader
            .load(locale)
            .pipe(
              catchError((err: unknown) => {
                this._logger.error('Translation file load issue');
                this._logger.error(err);
                return of({});
              }),
              map(
                (data: Translations | null): Translator => ({
                  locale,
                  formatter: new MessageFormat(locale),
                  translations: this.flatten(data ?? {}),
                })
              ),
              tap((translator: Translator) =>
                this._instant.set(locale, translator)
              )
            )
            .subscribe((translator: Translator) => resolve(translator));
        })
      );
    }

    return this._translators.get(locale) as Promise<Translator>;
  }

  /**
   * Translations observable by locale
   */
  private translator$(locale: Locale): Observable<Translator> {
    if (!this._translators.has(locale)) {
      throw new Error(`Locale ${locale} is not loaded or supported`);
    }
    return from(this._translators.get(locale) as Promise<Translator>);
  }

  /**
   * Returns translation from key (default to default locale if the translation does not exists)
   */
  public get(key: string, values?: TranslationValues): Observable<string> {
    return this._appState.locale$.pipe(
      distinctUntilChanged(),
      switchMap((locale: Locale) => this.translator$(locale)),
      switchMap((translator: Translator) => {
        if (
          translator.translations?.[this.clean(key)] ||
          translator.locale === Settings.defaultLocale
        ) {
          return of(translator);
        } else {
          return this.translator$(Settings.defaultLocale);
        }
      }),
      map((translator: Translator) => {
        return this.compile(
          translator.formatter,
          translator.translations?.[this.clean(key)] ?? key,
          values
        );
      })
    );
  }

  /**
   * Instant translation method
   */
  public instant(key: string, values?: TranslationValues): string {
    let translator: Translator | undefined = this._instant.get(
      this._appState.locale
    );
    if (
      !translator?.translations?.[this.clean(key)] &&
      translator?.locale !== Settings.defaultLocale
    ) {
      translator = this._instant.get(Settings.defaultLocale);
    }

    return translator
      ? this.compile(
          translator.formatter,
          translator.translations?.[this.clean(key)] ?? key,
          values
        )
      : '';
  }

  /**
   * Replace the params inside a translation with proper values
   */
  private compile(
    formatter: MessageFormat,
    message: string,
    values?: TranslationValues
  ): string {
    const compiler: MessageFunction<'string'> = formatter.compile(
      message ?? ''
    );
    return compiler(values ?? {});
  }

  /**
   * Transform the json translation to a flat key/value object
   */
  private flatten(data: Translations): FlattenedTranslations {
    const result: FlattenedTranslations = {};
    Object.keys(data).forEach((key: string) => {
      key = this.clean(key);
      const value: Translations | string = data[key];
      if (typeof value === 'object') {
        const flatObject: FlattenedTranslations = this.flatten(value);
        Object.keys(flatObject).forEach((subKey: string) => {
          result[`${key}.${subKey}`] = flatObject[subKey];
        });
      } else {
        result[key] = value;
      }
    });
    return result;
  }

  /**
   * Clean up a key
   */
  private clean(key: string): string {
    return key.replace(/\s+/g, ' ').trim();
  }
}
