<div
  #wrapper
  class="wrapper"
  (scroll)="onScroll()"
>
  <header [ngClass]="{ scroll: scrollTop }">
    <span>{{ title }}</span>
    <button
      app-icon-button
      src="assets/images/icons/close-xl.svg"
      [alt]="('Fermer' | translate | async) ?? ''"
      (click)="dismiss.emit()"
    ></button>
  </header>
  <div class="content">
    <ng-content select="[content]"></ng-content>
  </div>
  <footer [ngClass]="{ scroll: scrollBot }">
    <ng-content select="[footer]"></ng-content>
  </footer>
</div>
