export enum SchemaTypes {
  organization = 'Organization',
  website = 'WebSite',
  listItem = 'ListItem',
  itemList = 'ItemList',
  product = 'Product',
  aggregateRating = 'AggregateRating',
  offer = 'Offer',
  breadcrumbList = 'BreadcrumbList',
  searchAction = 'SearchAction',
}
