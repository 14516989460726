import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '../services/translations/translate.service';

@Pipe({
  name: 'translate',
})
export class TranslatePipe implements PipeTransform {
  public constructor(private readonly _translate: TranslateService) {}

  /**
   * Translate a string
   */
  public transform(
    key: string,
    values?: { [key: string]: string | number | boolean }
  ): Observable<string> {
    return this._translate.get(key, values);
  }
}
