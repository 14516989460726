import { Colors } from '../enums/colors.enum';

export const contrast = (hex: string): string => {
  hex = hex.replace('#', '');

  // Explain for 299 587 and 114 https://www.whydomath.org/node/wavlets/imagebasics.html
  const r: number = parseInt(hex.substring(0, 2), 16),
    g: number = parseInt(hex.substring(2, 4), 16),
    b: number = parseInt(hex.substring(4, 6), 16),
    brightness: number = (r * 299 + g * 587 + b * 114) / 1000;

  return brightness > 128 ? Colors.black : Colors.white;
};
