export abstract class Webservices {
  // Channel
  public static readonly channel: string = '/api/v2/shop/channels/:code';
  // Menu
  public static readonly menu: string = '/api/v2/shop/menus/:code';
  // Page
  public static readonly page: string = '/api/v2/shop/pages/:code';
  public static readonly pageBySlug: string =
    '/api/v2/shop/pages-by-slug/:slug';
  // Taxon
  public static readonly taxonBySlug: string =
    '/api/v2/shop/taxons-by-slug/:slug';
  // Variants
  public static readonly variants: string = '/api/v2/shop/product-variants';
  // Product
  public static readonly productBySlug: string =
    '/api/v2/shop/products-by-slug/:slug';
  // Auth
  public static readonly token: string = '/api/v2/shop/authentication-token';
  public static readonly customers: string = '/api/v2/shop/customers';
  public static readonly password: string =
    '/api/v2/shop/customers/:id/password';
  public static readonly verify: string =
    '/api/v2/shop/account-verification-requests';
  public static readonly forgottenPassword: string =
    '/api/v2/shop/reset-password-requests';
  public static readonly checkEmail: string = '/api/v2/shop/customers/verify';
  // Orders
  public static readonly orders: string = '/api/v2/shop/orders';
  public static readonly order: string = '/api/v2/shop/orders/:token';
  public static readonly orderItems: string =
    '/api/v2/shop/orders/:token/items';
  public static readonly orderItem: string =
    '/api/v2/shop/orders/:token/items/:id';
  public static readonly orderShipping: string =
    '/api/v2/shop/orders/:token/shipments/:shipmentId';
  public static readonly orderPayment: string =
    '/api/v2/shop/orders/:token/payments/:paymentId';
  public static readonly orderComplete: string =
    '/api/v2/shop/orders/:token/complete';
  // Return
  public static readonly returns: string = '/api/v2/shop/returns/:token/list';
  public static readonly returnReasons: string = '/api/v2/shop/return/reasons';
  public static readonly returnItems: string = '/api/v2/shop/returns/:token';
  public static readonly returnRequest: string = '/api/v2/shop/return/add';
  // Address
  public static readonly addresses: string = '/api/v2/shop/addresses';
  public static readonly address: string = '/api/v2/shop/addresses/:id';
  public static readonly deliverableCountries: string =
    '/api/v2/shop/countries';
  public static readonly countries: string = '/assets/data/countries.json';
  // News
  public static readonly news: string = '/api/v2/shop/news';
  public static readonly newsBySlug: string = '/api/v2/shop/news-by-slug/:slug';
  public static readonly events: string = '/api/v2/events';
  // Athletes
  public static readonly athleteBySlug: string =
    '/api/v2/shop/athletes-by-slug/:slug';
  // FAQ
  public static readonly faq: string = '/api/v2/shop/faq';
  // Newsletter
  public static readonly newsletter: string =
    '/api/v2/shop/newsletter-requests';
  // Assets
  public static readonly guides: string = '/assets/data/guides/:file';
  // Store locator
  public static readonly dealers: string = '/api/v2/shop/storelocator';
  // Dealer requests
  public static readonly dealerRequests: string =
    '/api/v2/shop/detaillant-requests';
  // Shipping methods
  public static readonly shippingMethods: string =
    '/api/v2/shop/shipping-methods';
  public static readonly shippingMethodDetails: string =
    '/api/v2/shop/shipping-methods/:code';
  public static readonly shippingUpsPickups: string =
    '/api/v2/shop/orders/:token/ups';
  // Payment methods
  public static readonly paymentMethods: string =
    '/api/v2/shop/payment-methods';
  public static readonly paymentMethodDetails: string =
    '/api/v2/shop/payment-methods/:code';
  public static readonly adyenSession: string =
    '/api/v2/shop/orders/payments/adyen/session';
  public static readonly paymentConfig: string =
    '/api/v2/shop/orders/:token/payments/:paymentId/configuration';
  // Alerts
  public static readonly stockAlert: string = '/api/v2/shop/alert-stock';
  // DoFinder
  public static readonly search: string = '/6/:hashId/_search';
  public static readonly suggestions: string = '/6/:hashId/_suggest';
}
