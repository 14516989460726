import { Component } from '@angular/core';
import { AbstractComponent } from '../abstract.component';

@Component({ template: '' })
export abstract class InputComponent<T> extends AbstractComponent {
  public onChange(_?: T): void {
    return undefined;
  }

  public onTouched(): void {
    return undefined;
  }

  public registerOnChange(fn: (_?: T) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public abstract writeValue(value?: T): void;
}
