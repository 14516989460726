import { Pipe, PipeTransform } from '@angular/core';
import { OrderItem } from '../models/dto/cart/order';
import { Bundle, BundleItem } from '../models/dto/catalog/bundle';
import { Variant } from '../models/dto/catalog/variant';
import { Collection } from '../models/dto/collection';

@Pipe({
  name: 'orderItemBundle',
})
export class OrderItemBundlePipe implements PipeTransform {
  public constructor() {}

  public transform(
    item: OrderItem,
    bundles?: Collection<Bundle>
  ): Bundle | undefined {
    const code: string | undefined = item.variant?.code;
    if (bundles && code) {
      const bundle: Bundle | undefined = Object.values(bundles).find(
        (bundle: Bundle) =>
          [...(bundle.items?.main ?? []), ...(bundle.items?.other ?? [])].some(
            (i: BundleItem) => i.variants?.some((v: Variant) => v.code === code)
          )
      );

      if (bundle) {
        return bundle;
      }
    }

    return undefined;
  }
}
