import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AbstractComponent } from '../../abstract.component';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector:
    'a[app-icon-button], button[app-icon-button], span[app-icon-button]',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent
  extends AbstractComponent
  implements OnChanges
{
  @Input({ required: true }) public src?: string;
  @Input({ required: true }) public alt?: string;
  @Input() public preset: 'none' | 'clear' | 'light' | 'grey' | 'dark' = 'none';
  @Input() public border: boolean = false;
  @Input() public large: boolean = false;

  public constructor(private readonly _element: ElementRef) {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['alt']) {
      this._element.nativeElement.setAttribute('title', this.alt);
    }
  }
}
