import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppStateService } from '../services/app-state.service';
import { MaintenanceService } from '../services/maintenance.service';
import { SsrResponseService } from '../services/ssr/ssr-response.service';
import { LoggerService } from '../services/sys/logger.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private _checking: boolean = false;

  public constructor(
    private readonly _logger: LoggerService,
    private readonly _ssrResponse: SsrResponseService,
    private readonly _appState: AppStateService,
    private readonly _maintenance: MaintenanceService
  ) {}

  /**
   * Http errors handle
   */
  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse): Observable<never> => {
        this._logger.error(
          `Http error ${error.status} - ${error.message}`,
          request.url,
          error.error
        );

        if (!error.status || error.status === 500) {
          // We don't want to re-check the maintenance status for the maintenance check request
          if (!this._checking && request.url !== this._maintenance.checkUrl) {
            this._checking = true;
            this._maintenance.check().subscribe((status: boolean) => {
              this._appState.maintenance = !status;
              this._checking = false;
            });
          }
        }

        this._ssrResponse.setStatus(error.status || 500, error.statusText);
        return throwError(() => error);
      })
    );
  }
}
