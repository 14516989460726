export abstract class Segments {
  public static readonly locale: string = ':locale';
  public static readonly home: string = '';
  public static readonly content: string = 'c';
  public static readonly taxon: string = 't';
  public static readonly product: string = 'p';
  public static readonly storeLocator: string = 's';
  public static readonly slug: string = ':slug';
  public static readonly account: string = 'account';
  public static readonly login: string = 'login';
  public static readonly register: string = 'register';
  public static readonly verify: string = 'verify/:token';
  public static readonly forgottenPassword: string = 'forgotten-password';
  public static readonly reset: string = 'reset/:token';
  public static readonly dashboard: string = 'dashboard';
  public static readonly informations: string = 'informations';
  public static readonly changePassword: string = 'change-password';
  public static readonly addressBook: string = 'address-book';
  public static readonly orders: string = 'orders';
  public static readonly orderDetails: string = 'orders/:token';
  public static readonly returnOrder: string = 'orders/:token/return';
  public static readonly returnSummary: string = 'orders/:token/return-summary';
  public static readonly cart: string = 'cart';
  public static readonly checkout: string = 'checkout/:token';
  public static readonly newsletter: string = 'newsletter';
  public static readonly search: string = 'search';
  public static readonly library: string = 'library';
  public static readonly notFound: string = 'not-found';
  public static readonly error: string = 'error';
  public static readonly news: string = 'n';
  public static readonly athlete: string = 'a';
  public static readonly shipping: string = 'shipping';
  public static readonly payment: string = 'payment';
  public static readonly complete: string = 'complete';
  public static readonly maintenance: string = 'maintenance';
}
